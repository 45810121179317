import {useNavigate} from "react-router-dom"
import {Button} from "primereact/button"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {candidateAppContext} from "../../../pages/MainPageCandidate"
import {useContext} from "react"

type ExperienceEditProps = {
    id: string|undefined
}
const ExperienceEditButton = (props: ExperienceEditProps) => {
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    let navigate = useNavigate()

    const handleClick = () => {
        if (props.id !== undefined){
            if(!!tempToken){
                navigate(`/${RouteFrontEnd.CANDIDATE}/${tempToken}/${RouteFrontEnd.EXPERIENCES}/${props.id}/edit`)
            }else{
                navigate(`/${RouteFrontEnd.EXPERIENCES}/edit/${props.id}`)
            }
        }
    }

    return (
        <>
            <Button onClick={handleClick} className='active:bg-blue-500 p-2' >
                <i className="pi pi-file-edit" style={{'fontSize': '1em'}}></i>
            </Button>
        </>
    )
}

export default ExperienceEditButton