import {ConfirmDialog} from "primereact/confirmdialog"
import {Button} from "primereact/button"
import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQueryClient} from "react-query"
import {useContext, useState} from "react"
import {deleteExperience} from "../../../networks/deleteNetwork"
import {candidateAppContext} from "../../../pages/MainPageCandidate"
import {refreshOnExperienceListChange, refreshOnVerificationListChange} from "../../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"

export interface DeleteExperienceParams {
    id: string,
    tempToken?: string
}

const ExperienceDelete = (props: { id: string, candidateId?: string }) => {
    let {id} = useParams()
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const roleStatus = useRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN], tempToken)
    let navigate = useNavigate()
    const queryClient = useQueryClient()
    const deletingMutation = useMutation<void, Error, DeleteExperienceParams>(deleteExperience, {
        onSuccess: (data) => {
            if(roleStatus.role === UserRoles.CANDIDATE){
                void refreshOnExperienceListChange(queryClient, candidateData.candidateId, id)
            }else {
                void refreshOnVerificationListChange(queryClient, props.candidateId ?? '')
            }
            analyticsEvent(AnalyticsEventCategory.DELETE_EXPERIENCE, {
                role: roleStatus.role,
                candidateId: candidateData.candidateId,
                experienceId: id
            })
            navigate(-1)
        },
        onError: (error) => {
            if (error.message === 'Forbidden') {
                setErrorMessage("You are not allowed to delete this experience. Experience is locked.")
                setErrorDialogVisible(true)
                console.log("You are not allowed to delete this experience. Experience is locked.")
            }
            console.log("Error deleting experience", error)
            analyticsEvent(AnalyticsEventCategory.DELETE_EXPERIENCE, {
                role: roleStatus.role,
                candidateId: candidateData.candidateId,
                experienceId: props.id,
                error: "Error deleting experience"
            })
        }
    })
    const [dialogVisible, setDialogVisible] = useState(false)
    const [errorDialogVisible, setErrorDialogVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const handleDeleteExperience = () => {
        if (props.id !== undefined) {
            deletingMutation.mutate({id: props.id, tempToken: tempToken})
        }
    }
    const handleDeleteExperienceError = () => {
        setErrorDialogVisible(false)
        setErrorMessage('')
    }
    const handleClick = () => {
        setDialogVisible(true)
    }

    return (
        <>
            <ConfirmDialog header="Error" visible={errorDialogVisible}
                           message={errorMessage}
                           icon="pi pi-error"
                           acceptClassName="p-button-danger"
                           rejectClassName="p-button-secondary hidden"
                           accept={() => handleDeleteExperienceError()}
                           onHide={() => {
                               setErrorDialogVisible(false)
                           }}/>
            <ConfirmDialog header="Delete" visible={dialogVisible} className="mobile-dialog"
                           message="Are you sure you want to delete this experience?"
                           icon="pi pi-exclamation-triangle"
                           acceptClassName="p-button-danger"
                           rejectClassName="p-button-secondary"
                           accept={() => handleDeleteExperience()}
                           reject={() => {
                               setDialogVisible(false)
                           }}
                           onHide={() => {
                               setDialogVisible(false)
                           }}/>
            <Button onClick={handleClick} className="bg-red-300 p-2 border-0">
                <i className="pi pi-trash" style={{'fontSize': '1em'}}></i>
            </Button>
        </>
    )
}

export default ExperienceDelete