import {UserRoles} from "../../../models/Names"
import {Button} from "primereact/button"
import {useNavigate} from "react-router-dom"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"

type AgencyEditButtonProps = {
    id: string | undefined
}
const AgencyEditButton = (props: AgencyEditButtonProps) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/${RouteFrontEnd.EDIT_AGENCIES}/${props.id}`)
    }

    return (
        <>
            <Button onClick={handleClick} className='active:bg-blue-500 p-2' >
                <i className="pi pi-file-edit" style={{'fontSize': '1em'}}></i>
            </Button>
        </>
    )
}
export default AgencyEditButton