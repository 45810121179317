import {useMutation, useQueryClient} from "react-query"
import {useContext, useState} from "react"
import {deleteVerification} from "../../../../networks/deleteNetwork"
import {ConfirmDialog} from "primereact/confirmdialog"
import {Button} from "primereact/button"
import {candidateAppContext} from "../../../../pages/MainPageCandidate"
import {refreshOnVerificationListChange} from "../../../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../../../services/analytics/analytics"
import useRoleStatus from "../../../../hooks/useRoleStatus"
import {UserRoles} from "../../../../models/Names"


export interface DeleteVerificationParams {
    id: string,
    tempToken?: string
}
const VerificationDelete = (props: { id: number, experienceId: number, locked: boolean }) => {
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const userStatus = useRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN], tempToken)
    const queryClient = useQueryClient()
    const deletingMutation = useMutation<void, Error, DeleteVerificationParams>(deleteVerification, {
        onSuccess: (data, { id }) => {
            void refreshOnVerificationListChange(queryClient, candidateData.candidateId, ''+props.experienceId, tempToken)
            analyticsEvent(AnalyticsEventCategory.DELETE_VERIFICATION, {
                role: userStatus.role,
                candidateId: candidateData.candidateId,
                experienceId: ""+props.experienceId,
                verificationId: id
            })
        },
        onError: (error) => {
            if(error.message === 'Forbidden'){
                setErrorMessage("You are not allowed to delete this verification. Verification is locked.")
                setErrorDialogVisible(true)
                console.log("You are not allowed to delete this verification. Verification is locked.")
            }
            console.log("Error deleting verification", error)
            analyticsEvent(AnalyticsEventCategory.DELETE_VERIFICATION, {
                role: userStatus.role,
                candidateId: candidateData.candidateId,
                experienceId: ""+props.experienceId,
                verificationId: ""+props.id,
                error: "Error deleting verification"
            })
        }
    })
    const [dialogVisible, setDialogVisible] = useState(false)
    const [errorDialogVisible, setErrorDialogVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const handleDeleteVerification = () => {
        if (props.id !== undefined){
            deletingMutation.mutate({id: props.id.toString(), tempToken: tempToken})
        }
    }
    const handleDeleteVerificationError = () => {
        setErrorDialogVisible(false)
        setErrorMessage('')
    }
    const handleClick = () => {
        setDialogVisible(true)
    }

    return (
        <>
            <ConfirmDialog header="Error" visible={errorDialogVisible}
                           message={errorMessage}
                           icon="pi pi-error"
                           acceptClassName="p-button-danger"
                           rejectClassName="p-button-secondary hidden"
                           accept={() => handleDeleteVerificationError()}
                           onHide={() => {setErrorDialogVisible(false)}} />
            <ConfirmDialog header="Delete" visible={dialogVisible} className='mobile-dialog'
                           message="Are you sure you want to delete this verification?"
                           icon="pi pi-exclamation-triangle"
                           acceptClassName="p-button-danger"
                           rejectClassName="p-button-secondary"
                           accept={() => handleDeleteVerification()}
                           reject={() => {setDialogVisible(false)}}
                           onHide={() => {setDialogVisible(false)}} />
            {!props.locked && <Button onClick={handleClick} className='col-1 bg-red-300 p-2 border-0' >
                <i className="pi pi-trash" style={{'fontSize': '1em'}}></i>
            </Button>}
        </>
    )
}

export default VerificationDelete