import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"

const ThankYou = () => {

    usePageAnalytics("/"+RouteFrontEnd.MANAGER, "ManagerThankYouPage")

    return(
        <>
            <br />
            <div className='col-8 bg-indigo-600 text-white-alpha-70 p-4 md:text-m lg:text-xl border-round-2xl align-items-center text-left ml-5'>
                <p>Thank you for choosing Signify as your trusted platform for verifying work experience. Your contribution is essential in helping us increase trust and transparency in the recruitment process.</p>
                <p>Best regards,</p>
                <p>Signify team</p>
            </div>
        </>
    )
}
export default ThankYou