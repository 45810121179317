import {useNavigate, useParams} from "react-router-dom"
import {Experience} from "../../../models/AllModels"
import {getQueryByIdNetwork, getQueryByParamsNetwork} from "../../../networks/getNetwork"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import ExperienceCard from "../../common/ExperienceCard"
import {ProgressSpinner} from "primereact/progressspinner";
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"

const ExperienceDetails = () => {
    let {id} = useParams()
    const navigate = useNavigate()
    const {
        isLoading: isLoadingExperience,
        isError: isErrorExperience,
        error: errorExperience,
        data: experience
    } = useQuery([NetworkEndpoints.EXPERIENCE_BY_ID, undefined, `ids=["${id}"]`], getQueryByParamsNetwork, {staleTime: 60000})
    const candidateId = experience?.map((e: Experience) => e.candidateId)
    const {
        isLoading: isLoadingCandidate,
        isError: isErrorCandidate,
        error: errorCandidate,
        data: candidate
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateId], getQueryByIdNetwork, {
        enabled: !!candidateId,
        staleTime: 60000
    })

    usePageAnalytics('/'+RouteFrontEnd.EXPERIENCES+'/'+id, 'ExperienceDetails')


    if (isLoadingExperience) return <ProgressSpinner/>
    if (isLoadingCandidate) return <ProgressSpinner/>

    const back = () => {
        navigate('/'+RouteFrontEnd.CANDIDATES+'/'+candidate._id, {replace: true})
    }

    return (
        <>
            <ExperienceCard experience={experience?.map((e: Experience) => e).pop()} candidate={candidate} back={back}/>
        </>
    )
}
export default ExperienceDetails