import {ExperienceCertificate} from "../../models/AllModels"
import VerificationsCertificateSegment from "./VerificationsCertificateSegment"
import {Fieldset} from "primereact/fieldset"
import verified from "../../assets/Verified.png"
import React from "react"
import {dateShow, getWorkMonthsBetween, getYearsAndMonthsString} from "../../utils/UtilFunctions"
import ExperienceWorkStatusView from "../MainApp/experience/ExperienceWorkStatusView"

type Props = {
    experience: ExperienceCertificate
}
const ExperienceCertificateSegment = (props: Props) => {

    const header = <div className="font-bold text-l flex relative">
            <span className="pt-2 pl-2 text-l font-bold"
                  style={{cursor: 'pointer'}}
            >
                {props.experience.jobTitle} at {props.experience.companyName}
        </span>
    </div>

    const effectiveMonths: number = getWorkMonthsBetween(props.experience.jobStartDate!, props.experience.jobEndDate!)
    const effectiveMonthsString: string = getYearsAndMonthsString(effectiveMonths)

    return (
        <Fieldset legend={header} toggleable>
            <div className="grid">
                <img alt="Verified" src={verified} className="w-4/5 mx-auto col-fixed" style={{width:"120px", height:"120px"}}/>
                <div className="col">
                    <div className='pt-3 flex'>
                        <div className='font-bold pr-2'>Company:</div>
                        {props.experience.companyName}</div>
                    <div className='pt-3 flex'>
                        <div className='font-bold pr-2'>Position:</div>
                        {props.experience.jobTitle}</div>
                    <div className='pt-3 flex'>
                        <div className='font-bold pr-2'>Duration:</div>
                        {effectiveMonthsString} ({dateShow(Number(props.experience.jobStartDate))} → {dateShow(Number(props.experience.jobEndDate))})
                    </div>
                    <ExperienceWorkStatusView jobOngoing={props.experience.jobOngoing} effectiveWorkMonths={effectiveMonths} jobFullTime={props.experience.jobFullTime} contractor={props.experience.contractor} continuous={props.experience.continuous}/>
                    <VerificationsCertificateSegment data={props.experience.verifications}/>
                </div>
            </div>
        </Fieldset>
    )
}

export default ExperienceCertificateSegment