import {Verification} from "../../models/AllModels"
import {DataScroller} from "primereact/datascroller"
import VerificationListItem from "./VerificationListItem"
import { useQuery} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork} from "../../networks/getNetwork"
import {useContext} from "react"
import {candidateAppContext} from "../../pages/MainPageCandidate"

type Props = {
    experienceId: string | undefined
}
const VerificationList = (props: Props) => {
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const verificationListData = useQuery([NetworkEndpoints.VERIFICATION_BY_EXPERIENCE, tempToken, props.experienceId], getQueryByTempTokenNetwork, {staleTime: 60000})

    const itemTemplate = (data: Verification) => {
        return <VerificationListItem verification={data} />
    }
    const statusesLabels =
        <div className="flex flex-row align-items-center mt-2 mb-1">
            <div className="flex flex-col bg-white text-xs" style={{fontSize: "40%"}}>Legend:</div>
            <div className="flex flex-col w-1rem bg-white"/>
            <div className="flex flex-col p-1 text-white text-xs bg-gray-400" style={{fontSize: "40%"}}>Draft</div>
            <div className="flex flex-col w-1rem bg-white"/>
            <div className="flex flex-col p-1 text-white text-xs bg-orange-400" style={{fontSize: "40%"}}>Awaiting</div>
            <div className="flex flex-col w-1rem bg-white"/>
            <div className="flex flex-col p-1 text-white text-xs bg-red-400" style={{fontSize: "40%"}}>Rejected<br/>TimeOut</div>
            <div className="flex flex-col w-1rem bg-white"/>
            <div className="flex flex-col p-1 text-white text-xs bg-green-400" style={{fontSize: "40%"}}>Accepted</div>
        </div>

    return (
        <>
            <div className=''>
                <DataScroller value={verificationListData.data} itemTemplate={itemTemplate} rows={100} inline scrollHeight="350px" header="Verifications" />
            </div>
            <div className="flex shadow-2 lg:ml-6 md:ml-0 mr-6 pl-2 border-round"> {statusesLabels}</div>
        </>
    )
}
export default VerificationList