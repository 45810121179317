import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {baseAuthUrl, baseUrl, getToken} from "../utils/NetworkUtil"
import {Constants} from "../models/Names"
import {DtoAgency, DtoBalance, DtoExperience, DtoInvite, DtoLogin, DtoUser, DtoVerification} from "../models/DtoModels"

const postNetwork = async <T>(myUrl: string, obj?: T, tempToken?: string) => {
    let token = await getToken()

    if(!!tempToken){
        token = tempToken
    }

    const response = await fetch(`${myUrl}`, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

const refreshTokenNetwork = async () => {
    const authBase = process.env["REACT_APP_AUTH_BASE_URL"]
    let refreshToken = localStorage.getItem(Constants.REFRESH_TOKEN) || undefined

    // console.log("Refresh token", refreshToken)
    // if (!refreshToken) {
    //     throw new Error('Need to login!')
    // }

    const response = await fetch(`${authBase}/${NetworkEndpoints.REFRESH_TOKEN}`, {
        method: 'POST',
        body: JSON.stringify({refreshToken}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`
        }
    })
    if(response.status === 401){
        console.log("401 error for refresh token")
        localStorage.removeItem(Constants.REFRESH_TOKEN)
        localStorage.removeItem(Constants.TOKEN)
        throw new Error('Need to login!')
    }
    let token = await response.json()
    localStorage.setItem(Constants.TOKEN, token.accessToken)
    return token.accessToken
}

const postLoginNetworkImpl = async (login: DtoLogin) => {
    const response = await fetch(`${baseAuthUrl}/${NetworkEndpoints.LOGIN}`, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + window.btoa(`${login.email}:${login.password}`),
            'Content-Type': 'application/json',
        }
    })
    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

export const postRefreshTokenNetwork = async () => {
    return refreshTokenNetwork()
}

export const postCandidateNetwork = async (candidate: DtoUser) => {
    const url = baseUrl+'/'+ NetworkEndpoints.CANDIDATE
    return postNetwork(url, candidate)
}

export const postAgencyNetwork = async (agency: DtoAgency) => {
    const url = baseUrl+'/'+NetworkEndpoints.ADMIN_AGENCIES
    return postNetwork(url, agency)
}

export const postBalance = async (balance: DtoBalance) => {
    const url = baseUrl+'/'+NetworkEndpoints.BALANCE+"/"+balance.agencyId+"/internal"
    return postNetwork(url, {
        creditsCount: +balance.creditsCount,
        amountPaid: +balance.amountPaid,
        currency: balance.currency
    })
}

export const postAdminUserInvite = async (dto:DtoInvite) => {
    const url = baseAuthUrl+'/'+NetworkEndpoints.ADMIN_INVITE
    return postNetwork(url, {
        email: dto.email
    })
}

export const postTokenRepack = async (agencyId: string) => {
    const url = baseAuthUrl+'/'+NetworkEndpoints.ADMIN_TOKEN_REPACK
    return postNetwork(url, {agencyId: agencyId})
}

export const postLoginNetwork = async (login: DtoLogin) => {
    return postLoginNetworkImpl(login)
}

export const postExperienceNetwork = async (experience: DtoExperience) => {
    const url = baseUrl+'/'+NetworkEndpoints.EXPERIENCES
    if(!!experience.token){
        return postNetwork(url, experience, experience.token)
    }else {
        return postNetwork(url, experience)
    }
}

export const postVerificationNetwork = async (verification: DtoVerification) => {
    const url = baseUrl+'/'+NetworkEndpoints.VERIFICATION
    if (!!verification.token) {
        return postNetwork(url, verification, verification.token)
    } else{
        return postNetwork(url, verification)
    }
}

export const postRegistration = async (dto: { name: string, password: string, token: string} ) => {
    const url = baseAuthUrl+'/'+NetworkEndpoints.REGISTRATION
    return postNetwork(url, {
        name: dto.name,
        password: dto.password,
    }, dto.token)
}

export default postNetwork