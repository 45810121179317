import {useEffect, useState} from "react"
import {Constants, UserRoles} from "../models/Names"
import jwtDecode from "jwt-decode"

type RoleStatus = {
    isAuthenticated: boolean
    isFinished: boolean
    role: UserRoles
}
const useRoleStatus = (roles: UserRoles[], tempToken?: string | undefined) => {
    const [isAuthenticated, setIsAuthenticated] = useState({
        isAuthenticated: false,
        isFinished: false,
        role: UserRoles.CANDIDATE
    } as RoleStatus)

    useEffect(() => {
        let token: string | undefined = undefined
        if(!!tempToken){
            token = tempToken
        }else {
            token = localStorage.getItem(Constants.TOKEN) || undefined
        }
        if(!token){
            setIsAuthenticated({...isAuthenticated, isFinished: true, isAuthenticated: false})
        }else{
            const tokenDecoded:{role: string} = jwtDecode(token)
            if(!!(roles.find(role => role === tokenDecoded.role))){
                setIsAuthenticated({...isAuthenticated, isAuthenticated: true, isFinished: true, role: tokenDecoded.role as UserRoles})
            }else {
                setIsAuthenticated({...isAuthenticated, isAuthenticated: false, isFinished: true, role: tokenDecoded.role as UserRoles})
            }
        }
    }, [])
    return isAuthenticated
}

export default useRoleStatus