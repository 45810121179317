import {useNavigate, useParams} from "react-router-dom"
import {QueryClient, useMutation, useQuery, useQueryClient} from "react-query"
import {getQueryByIdNetwork} from "../../../networks/getNetwork"
import {postExperienceNetwork} from "../../../networks/postNetwork"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {useRef} from "react"
import {Toast} from "primereact/toast"
import NewExperienceCard, {NewExperienceFormType} from "../../common/NewExperienceCard"
import {refreshOnExperienceListChange} from "../../../utils/RefreshFunctions"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"

const NewExperienceForm = () => {
    let navigate = useNavigate()
    let {id} = useParams()
    const candidate = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, id], getQueryByIdNetwork, {staleTime: 60000})
    const queryClient: QueryClient = useQueryClient()
    const userStatus = useRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN])
    const {mutate} = useMutation({
        mutationFn: postExperienceNetwork,
        onSuccess: (data, variables, context) => {
            void refreshOnExperienceListChange(queryClient, id)
            analyticsEvent(AnalyticsEventCategory.CREATE_EXPERIENCE, {
                role: userStatus.role,
                candidateId: id,
                experienceId: data.insertedId
            })
            navigate(`/${RouteFrontEnd.EXPERIENCES}/${data.insertedId}`)
        },
        onError: (error, variables, context) => {
            analyticsEvent(AnalyticsEventCategory.CREATE_EXPERIENCE, {
                role: userStatus.role,
                candidateId: id,
                experienceId: variables.experienceId,
                error: "Error creating experience"
            })
        }
    })

    const toast = useRef(null) as any

    usePageAnalytics('/'+RouteFrontEnd.CANDIDATES+'/'+id+'/'+RouteFrontEnd.NEW_EXPERIENCE, 'New Experience Form')

    const postNewExperience = (values: NewExperienceFormType) => {
        mutate({
            candidateId: '' + candidate.data?._id,
            companyName: values.companyName,
            jobTitle: values.jobTitle,
            jobStartDate: values.jobStartDate,
            jobEndDate: values.jobEndDate,
            jobOngoing: values.jobOngoing,
            jobFullTime: values.jobFullTime,
            continuous: values.continuous,
            contractor: values.contractor,
            effectiveWorkMonths: values.effectiveWorkMonths
        })
        showSuccess()
    }

    const showSuccess = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully added experience',
            life: 3000
        })
    }

    const header = <div className="font-bold text-l flex relative">
        <div onClick={() => navigate(-1)} style={{cursor: 'pointer'}}>
            <i className="pi pi-angle-left text-xl mr-2"
               style={{cursor: 'pointer'}}></i>
            <span className='pt-2 pl-2 text-l font-bold'
                  style={{cursor: 'pointer'}}
            >{candidate?.data?.name}
        </span>
        </div>

    </div>

    return (
        <>
            <Toast ref={toast}/>
            <NewExperienceCard header={header} sendExperience={postNewExperience}/>
        </>
    )
}
export default NewExperienceForm