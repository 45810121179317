import {useNavigate} from "react-router-dom"
import {Button} from "primereact/button"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {UserRoles} from "../../../models/Names"

type CandidateEditProps = {
    id: string|undefined,
    role: UserRoles,
    token?: string|undefined
}
const CandidateEditButton = (props: CandidateEditProps) => {
    let navigate = useNavigate()

    const handleClick = () => {
        if (!!props.id ){
            //Agency only
            navigate(`/${RouteFrontEnd.UPDATE_CANDIDATE}/${props.id}`)
        }
    }

    return (
        <>
            <Button onClick={handleClick} className='active:bg-blue-500 p-2' >
                <i className="pi pi-file-edit" style={{'fontSize': '1em'}}></i>
            </Button>
        </>
    )
}

export default CandidateEditButton
