import {ManagerConfirmProps} from "./ManagerConfirm"
import React, {SyntheticEvent, useState} from "react"
import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {Button} from "primereact/button"
import {Card} from "primereact/card"
import UserExperienceDesc from "./UserExperienceDesc"
import {InputTextarea} from "primereact/inputtextarea"
import {useFormik} from "formik"
import * as Yup from "yup"
import {NeltQuestionsSerbian, neltSendButtonByLanguage, neltTitle, questionByLanguage} from "../../models/NeltQuestions"
import serbianFlag from "../../assets/serbian_flag.png"
import englishFlag from "../../assets/uk_flag.jpeg"
import {SelectButton} from "primereact/selectbutton"

const ManagerNeltQuestions = (props: ManagerConfirmProps) => {
    const handleBack = (event: SyntheticEvent) => {
        event.preventDefault()
        props.onBack()
    }


    usePageAnalytics("/" + RouteFrontEnd.MANAGER, "ManagerNeltQuestions")
    const [language, setLanguage] = useState("sr")

    const formik = useFormik({
        initialValues: {
            a1: '',
            a2: '',
            a3: '',
            a4: '',
            a5: '',
            a6: '',
            a7: '',
            a8: '',
            a9: '',
            a10: '',
            a11: '',
            a12: '',
            a13: ''
        },
        onSubmit: (values: any): void => {
            console.log("ManagerNeltQuestions Submit answers", values)
            props.onSubmit(
                 NeltQuestionsSerbian[0] + '\n' + formik.values.a1 + '\n' +
                NeltQuestionsSerbian[1] + '\n' + formik.values.a2 + '\n' +
                NeltQuestionsSerbian[2] + '\n' + formik.values.a3 + '\n' +
                NeltQuestionsSerbian[3] + '\n' + formik.values.a4 + '\n' +
                NeltQuestionsSerbian[4] + '\n' + formik.values.a5 + '\n' +
                NeltQuestionsSerbian[5] + '\n' + formik.values.a6 + '\n' +
                NeltQuestionsSerbian[6] + '\n' + formik.values.a7 + '\n' +
                NeltQuestionsSerbian[7] + '\n' + formik.values.a8 + '\n' +
                NeltQuestionsSerbian[8] + '\n' + formik.values.a9 + '\n' +
                NeltQuestionsSerbian[9] + '\n' + formik.values.a10 + '\n' +
                NeltQuestionsSerbian[10] + '\n' + formik.values.a11 + '\n' +
                NeltQuestionsSerbian[11] + '\n' + formik.values.a12 + '\n' +
                NeltQuestionsSerbian[12] + '\n' + formik.values.a13 + '\n'
            )
            formik.resetForm()
        },
        validationSchema: Yup.object({
            a1: Yup.string().required('Required'),
            a2: Yup.string().required('Required'),
            a3: Yup.string().required('Required'),
            a4: Yup.string().required('Required'),
            a5: Yup.string().required('Required'),
            a6: Yup.string().required('Required'),
            a7: Yup.string().required('Required'),
            a8: Yup.string().required('Required'),
            a9: Yup.string().required('Required'),
            a10: Yup.string().required('Required'),
            a11: Yup.string().required('Required'),
            a12: Yup.string().required('Required'),
            a13: Yup.string().required('Required')
        })
    } as any) as any

    const header = <div className="font-bold md:text-m lg:text-xl text-gray-800">{neltTitle(props.user?.name, props.experience?.companyName, language)}
    </div>
    const back = <div onClick={handleBack}>
        <i className="pi pi-angle-left mr-2"
           style={{
               'fontSize': '2em',
               cursor: 'pointer'
           }}/>
    </div>
    const justifyTemplate = (option: "sr" | "en") => {
        return <img alt="Verified" src={flagForCountry(option)} className="p-0" style={{width:"50px", height:"33px"}}/>
    }

    const flagForCountry = (option: "sr" | "en") =>{
        if(option === "sr"){
            return serbianFlag
        }else{
            return englishFlag
        }
    }

    return (
        <>
            <Card header={back} className="p-card p-4 m-4 shadow-3">
                <SelectButton className="flex flex-row-reverse flex-wrap" style={{ marginBottom: "25px"}} value={language} options={["sr", "en"]} onChange={(e) => setLanguage(e.value)} itemTemplate={justifyTemplate}  optionLabel="language" />
                <UserExperienceDesc user={props.user} experience={props.experience}/>
            </Card>
            <Card header={header} className="p-card p-4 m-4 shadow-3">
                <form onSubmit={(e: any): void => {
                    e.preventDefault()
                    formik.handleSubmit(e)
                }}>
                    <div className="font-bold pb-2 md:text-m lg:text-xl text-gray-800">{questionByLanguage(0, language)}
                    </div>
                    <InputTextarea id="a1" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a1')}/>
                    <small id="a1-error" className="p-error block">{formik.errors && formik.errors.a1}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(1, language)}
                    </div>
                    <InputTextarea id="a2" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a2')}/>
                    <small id="a2-error" className="p-error block">{formik.errors && formik.errors.a2}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(2, language)}
                    </div>
                    <InputTextarea id="a3" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a3')}/>
                    <small id="a3-error" className="p-error block">{formik.errors && formik.errors.a3}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(3, language)}
                    </div>
                    <InputTextarea id="a4" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a4')}/>
                    <small id="a4-error" className="p-error block">{formik.errors && formik.errors.a4}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(4, language)}
                    </div>
                    <InputTextarea id="a5" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a5')}/>
                    <small id="a5-error" className="p-error block">{formik.errors && formik.errors.a5}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(5, language)}
                    </div>
                    <InputTextarea id="a6" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a6')}/>
                    <small id="a6-error" className="p-error block">{formik.errors && formik.errors.a6}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(6, language)}
                    </div>
                    <InputTextarea id="a7" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a7')}/>
                    <small id="a7-error" className="p-error block">{formik.errors && formik.errors.a7}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(7, language)}
                    </div>
                    <InputTextarea id="a8" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a8')}/>
                    <small id="a8-error" className="p-error block">{formik.errors && formik.errors.a8}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(8, language)}
                    </div>
                    <InputTextarea id="a9" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a9')}/>
                    <small id="a9-error" className="p-error block">{formik.errors && formik.errors.a9}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(9, language)}
                    </div>
                    <InputTextarea id="a10" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a10')}/>
                    <small id="a10-error" className="p-error block">{formik.errors && formik.errors.a10}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(10, language)}
                    </div>
                    <InputTextarea id="a11" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a11')}/>
                    <small id="a11-error" className="p-error block">{formik.errors && formik.errors.a11}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(11, language)}
                    </div>
                    <InputTextarea id="a12" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a12')}/>
                    <small id="a12-error" className="p-error block">{formik.errors && formik.errors.a12}</small>

                    <div className="font-bold pb-2 pt-4 md:text-m lg:text-xl text-gray-800">{questionByLanguage(12, language)}
                    </div>
                    <InputTextarea id="a13" className="col-12 focus" rows={5} cols={30}
                                   {...formik.getFieldProps('a13')}/>
                    <small id="a13-error" className="p-error block">{formik.errors && formik.errors.a13}</small>

                    <Button type="submit" className="mt-6">{neltSendButtonByLanguage(language)}</Button>
                </form>
            </Card>
        </>
    )
}

export default ManagerNeltQuestions