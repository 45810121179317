import {Outlet, useNavigate, useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {UserRoles} from "../models/Names"
import usePageAnalytics from "../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import {getCandidateDataFromToken} from "../utils/getCandidateDataFromToken"

export type CandidateContextType = {
    id: string,
    candidateId: string
    role: UserRoles,
    exp: number,
    isConsentGiven: boolean
}
export const candidateAppContext = React.createContext({
    candidateData: {
        id: "",
        candidateId: "",
        role: UserRoles.CANDIDATE,
        exp: 0,
        isConsentGiven: false
    },
    setCandidateData: (token: CandidateContextType) => {}
})



const MainPageCandidate = () => {
    const {id} = useParams()
    const navigation = useNavigate()
    const [candidateData, setCandidateData] = useState({
        id: "",
        candidateId: "",
        role: UserRoles.CANDIDATE,
        exp: 0,
        isConsentGiven: false
    })

    usePageAnalytics("/"+RouteFrontEnd.CANDIDATE+'/'+id, "MainPageCandidate")

    useEffect(() => {
        const result = getCandidateDataFromToken(id)
        if("role" in result) {
            setCandidateData(result)
        } else {
            navigation('/error') //TODO: Add token error page
        }
    }, [id])

    return (
        <div className="App">
            <div className="card p-4">
                <candidateAppContext.Provider value={{candidateData, setCandidateData}}>
                    <Outlet />
                </candidateAppContext.Provider>
            </div>
        </div>
    )
}

export default MainPageCandidate