import {
    Statuses
} from "../../models/AllModels"
import {
    attestationStatusToColor,
    signatureStatusToColor,
    signifyInspectionStatusToColor
} from "../../utils/StatusesToColor"


const StatusesPreview = ({statuses}: { statuses: Statuses }) => {

    return (
        <>
            <div className="flex mt-2 mr-2 z-0">
                <div
                    className={'flex flex-col h-2rem w-3 text-white p-1 ' + attestationStatusToColor(statuses.attestationStatus)}>Referee
                </div>
                <div className="flex flex-col h-1rem w-1rem bg-white"></div>
                <div
                    className={'flex flex-col h-2rem w-3 text-white p-1 ' + attestationStatusToColor(statuses.attestationStatus)}>AI
                </div>
                <div className="flex flex-col h-1rem w-1rem bg-white"></div>
                <div
                    className={'flex flex-col h-2rem w-3 text-white p-1 ' + signifyInspectionStatusToColor(statuses.signifyInspectionStatus)}>Signify
                </div>
                <div className="flex flex-col h-1rem w-1rem bg-white"></div>
                <div
                    className={'flex flex-col h-2rem w-3 text-white p-1 ' + signatureStatusToColor(statuses.signatureStatus)}>BlockChain
                </div>
            </div>
        </>
    )
}

export default StatusesPreview