import {Card} from "primereact/card"
import {Button} from "primereact/button"
import React, {SyntheticEvent, useState} from "react"
import UserExperienceDesc from "./UserExperienceDesc"
import ManagerTitleAndConsent from "./ManagerTitleAndConsent"
import {Experience, User, Verification} from "../../models/AllModels"
import logo from "../../resources/LogoSymbolOnly.png"

type ManagerProps = {
    user: User | undefined,
    experience: Experience | undefined,
    verification: Verification | undefined,
    title: string,
    onConfirm: () => void,
    onDeny: () => void,
    onTitleChange: (text: string) => void
}

const ManagerContent = ({user, experience, verification, title, onConfirm, onDeny, onTitleChange}: ManagerProps) => {
    const [consent, setConsent] = useState<boolean>(false)
    const handleConfirm = (event: SyntheticEvent) => {
        onConfirm()
    }
    const handleDeny = (event: SyntheticEvent) => {
        onDeny()
    }

    const onConsentChange = (isConsentGiven: boolean) => {
        setConsent(isConsentGiven)
    }

    const disableButtons = () => title.length<2 || consent


    const footer = <div className="grid align-items-center justify-content-center">
        <Button onClick={handleConfirm}
                className="col-4 align-items-center justify-content-center bg-blue-600 font-bold text-white-alpha-80 m-2 px-5 py-3 border-round border-0"
                disabled={disableButtons()}>Confirm</Button>
        <div className='col-1'/>
        <Button onClick={handleDeny}
                className="col-4 align-items-center justify-content-center bg-yellow-500 font-bold text-white-alpha-80 m-2 px-5 py-3 border-round border-0"
                disabled={disableButtons()}>Deny</Button>
    </div>

    const header = <div className='grid'>
        <div className='col-1'><img src={logo} alt="logo" height="20" width="30" className="mr-2 w-4rem h-4rem"></img>
        </div>
    </div>

    return (
        <>
            <Card header={header}
                  className=" p-card p-4 m-4 shadow-3 align-items-center justify-content-center p-card lg:text md:text-s">
                <p>{verification?.refereeName}, we appreciate your help with our inquiry about {user?.name}'s work
                    experience at {experience?.companyName}. Please: </p>
                <p>1. Click <b>CONFIRM</b> if you agree with the position, time, and company details. Then, share your
                    feedback
                    on their cooperation, performance, and work ethic at {experience?.companyName}.</p>
                <p>2. Click <b>DENY</b> if you disagree with any information. Please explain your choice in the
                    comments.</p>
            </Card>
            <ManagerTitleAndConsent verification={verification} title={title} onTitleChange={onTitleChange} onSetConsent={onConsentChange}/>
            <Card footer={footer} className="p-card p-4 m-4 shadow-3">
                <UserExperienceDesc user={user} experience={experience}/>
            </Card>
        </>
    )
}

export default ManagerContent