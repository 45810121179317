import {
    AttestationStatus,
    CheckStatus,
    SignatureStatus,
    SignifyInspectionStatus
} from "../models/AllModels"

export const attestationStatusToColor = (status: AttestationStatus ) => {
  switch ( status ) {
    case AttestationStatus.Draft:
      return 'bg-gray-400';
    case AttestationStatus.TimedOut:
      return 'bg-red-400';
    case AttestationStatus.Sent:
      return 'bg-orange-400';
    case AttestationStatus.Rejected:
      return 'bg-red-400';
    case AttestationStatus.Accepted:
      return 'bg-green-400';
  }
}

export const checkStatusToColor = ( status: CheckStatus ) => {
  switch ( status ) {
    case CheckStatus.Draft:
      return 'bg-gray-400';
    case CheckStatus.Suspicious:
      return 'bg-red-400';
    case CheckStatus.Consistent:
      return 'bg-green-400';
  }
}

export const signifyInspectionStatusToColor = ( status: SignifyInspectionStatus ) => {
  switch ( status ) {
    case SignifyInspectionStatus.Draft:
      return 'bg-gray-400';
    case SignifyInspectionStatus.Awaiting:
      return 'bg-orange-400';
    case SignifyInspectionStatus.Rejected:
      return 'bg-red-400';
    case SignifyInspectionStatus.Accepted:
      return 'bg-green-400';
  }
}

export const signatureStatusToColor = ( status: SignatureStatus ) => {
  switch ( status ) {
    case SignatureStatus.Draft:
      return 'bg-gray-400';
    case SignatureStatus.Submitted:
      return 'bg-green-400';
    // case SignatureStatus.Signed:
    //   return 'bg-green-400';
  }
}