import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {getQueryByIdNetwork, getQueryByParamsNetwork} from "../../../networks/getNetwork"
import {dateMilitaryFormat, dateParse} from "../../../utils/UtilFunctions"
import {Experience} from "../../../models/AllModels"
import {useRef} from "react"
import {putExperienceNetwork} from "../../../networks/putNetwork"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {Toast} from "primereact/toast"
import EditExperienceCard from "../../common/EditExperienceCard"
import {DtoUpdateExperience} from "../../../models/DtoModels"
import {refreshOnExperienceChange} from "../../../utils/RefreshFunctions"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import UseRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"

const ExperienceEditForm = () => {
    let navigate = useNavigate()
    let {id} = useParams()
    const {
        isLoading: isLoadingExperience,
        isError: isErrorExperience,
        error: errorExperience,
        data: experience
    } = useQuery([NetworkEndpoints.EXPERIENCE_BY_ID, undefined, `ids=["${id}"]`], getQueryByParamsNetwork, {staleTime: 60000})
    const candidateId = experience?.map((e: Experience) => e.candidateId)
    const {
        isLoading: isLoadingCandidate,
        isError: isErrorCandidate,
        error: errorCandidate,
        data: candidate
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateId], getQueryByIdNetwork, {
        enabled: !!candidateId,
        staleTime: 60000
    })
    const experienceId = experience?.map((e: Experience) => e._id)
    const roleStatus = UseRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN])

    const toast = useRef(null)
    const queryClient = useQueryClient()
    const {mutate} = useMutation({
        mutationFn: putExperienceNetwork,
        onSuccess: () => {
            void refreshOnExperienceChange(queryClient, candidateId, experienceId) //Maybe it should use .then for navigate
            analyticsEvent(AnalyticsEventCategory.UPDATE_EXPERIENCE, {
                role: roleStatus.role,
                candidateId: candidateId,
                experienceId: experienceId
            })
            navigate(-1)
        },
        onError: () => {
            showError()
            analyticsEvent(AnalyticsEventCategory.UPDATE_EXPERIENCE, {
                role: roleStatus.role,
                candidateId: candidateId,
                experienceId: experienceId,
                error: "Experience update error"
            })
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.UPDATE_EXPERIENCE+'/'+id, 'Update Experience')

    const showError = () => {
        // @ts-ignore
        toast?.current?.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Experience is locked. You are not allowed to edit this experience.',
            life: 3000
        })
    }

    const updateExperience = (values: DtoUpdateExperience) => {
        const startD: number = dateMilitaryFormat(new Date(values.jobStartDate))
        const endD: number = dateMilitaryFormat(new Date(values.jobEndDate))
        const effectiveMonths = values.continuous ? undefined : values.effectiveWorkMonths
        mutate({
            experienceId: values.experienceId,
            candidateId: values.candidateId,
            companyName: values.companyName,
            jobTitle: values.jobTitle,
            jobStartDate: startD,
            jobEndDate: endD,
            jobOngoing: values.jobOngoing,
            jobFullTime: values.jobFullTime,
            continuous: values.continuous,
            contractor: values.contractor,
            effectiveWorkMonths: effectiveMonths
        })
    }



    return (
        <>
            <Toast ref={toast}/>
            <EditExperienceCard initValues={
                {
                    experienceId: experience?.[0]._id,
                    candidateId: experience?.[0].candidateId,
                    candidateName: candidate?.name,
                    companyName: experience?.[0].companyName,
                    jobTitle: experience?.[0].jobTitle,
                    jobStartDate: dateParse(experience?.[0].jobStartDate),
                    jobEndDate: dateParse(experience?.[0].jobEndDate),
                    jobOngoing: experience?.[0].jobOngoing,
                    jobFullTime: experience?.[0].jobFullTime,
                    continuous: experience?.[0].continuous,
                    contractor: experience?.[0].contractor,
                    effectiveWorkMonths: experience?.[0].effectiveWorkMonths
                }
            } onUpdate={updateExperience}/>
        </>
    )
}

export default ExperienceEditForm