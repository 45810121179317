import {useEffect, useRef, useState} from "react"
import {useMutation, useQueryClient} from "react-query"
import {postCandidateNetwork} from "../../../networks/postNetwork"
import {useNavigate} from "react-router-dom"
import {Toast} from "primereact/toast"
import NewCandidateFormCard from "../../common/NewCandidateFormCard"
import {DtoUser} from "../../../models/DtoModels"
import {patchInviteCandidate} from "../../../networks/patchNetwork"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {refreshOnCandidateListChange} from "../../../utils/RefreshFunctions"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"

const NewCandidateForm = () => {
    const navigate = useNavigate()
    const [state, setState] = useState<DtoUser>({} as DtoUser)
    const [candidateId, setCandidateId] = useState<string>('')
    const [isInvited, setIsInvited] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const roleStatus = useRoleStatus([UserRoles.ADMIN, UserRoles.AGENCY])
    const {isError, mutate} = useMutation({
        mutationFn: postCandidateNetwork,
        onSuccess: async (data) => {
            refreshOnCandidateListChange(queryClient) //Maybe it should use .then for setCandidateId
            setCandidateId(data.insertedId)
            analyticsEvent(AnalyticsEventCategory.CREATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId: candidateId
            })
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.CREATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId: candidateId,
                error: "Error creating candidate"
            })
        }
    })

    const mutateInvite = useMutation({
        mutationFn: patchInviteCandidate,
        onError: () => {
            showError()
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId: candidateId,
                error: "Error inviting candidate"
            })
        },
        onSuccess: () => {
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId: candidateId
            })
            navigate("/" + RouteFrontEnd.CANDIDATES, {replace: true})
            navigate(`/${RouteFrontEnd.CANDIDATES}/${candidateId}/${RouteFrontEnd.EXPERIENCES}/new`)
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.NEW_CANDIDATE, 'New Candidate Form')

    useEffect(() => {
        if (!!candidateId) {
            if (isInvited) {
                let lowerCaseEmail: string | null = null
                if (state.email !== undefined && state.email !== null) {
                    lowerCaseEmail = (state.email as string).toLowerCase();
                }
                mutateInvite.mutate({id: candidateId, email: lowerCaseEmail ?? ""})
            }
            navigate(-5)
        }
    }, [candidateId])

    const toast = useRef(null)

    const showSuccess = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully added candidate',
            life: 3000
        })
    }

    const showError = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Error in sending invitation to candidate contact support',
            life: 3000
        })
    }

    const newCandidateSend = (values: { name: string, email: string, country: string, position: string, isInvited: boolean }) => {
        mutate({...state, name: values.name, email: values.email, country: values.country, position: values.position})
        setIsInvited(values.isInvited)
    }

    if (isError) {
        return <div>Error in saving Candidate</div>
    }

    return (
        <div>
            <Toast ref={toast}/>
            <NewCandidateFormCard submit={newCandidateSend}/>
        </div>
    )
}
export default NewCandidateForm