import {NavigateFunction, useNavigate} from "react-router-dom"
import {useEffect} from "react"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import {Constants, UserRoles} from "../models/Names"
import {analyticsEvent, AnalyticsEventCategory} from "../services/analytics/analytics"
import useRoleStatus from "./useRoleStatus"

const useLogout = (flag: boolean = false): void => {
    const navigate: NavigateFunction = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.ADMIN, UserRoles.AGENCY])

    useEffect((): void => {
        if (flag) {
            console.log("Logging out.", flag)
            localStorage.removeItem(Constants.TOKEN)
            localStorage.removeItem(Constants.REFRESH_TOKEN)
            analyticsEvent(AnalyticsEventCategory.LOGOUT, {
                role: roleStatus.role,
            })
            navigate(RouteFrontEnd.LOGIN)
        }
    }, [flag, navigate])
}

export default useLogout