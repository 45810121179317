import jwtDecode from "jwt-decode"
import {postRefreshTokenNetwork} from "../networks/postNetwork"
import {Constants} from "../models/Names"

export const isTokenExpired = (token: string): boolean => {
    const decodedToken: any = jwtDecode(token)
    const currentTime: number = Date.now() / 1000
    return decodedToken.exp < currentTime
}

export const baseUrl = process.env["REACT_APP_URL_BASE"]
export const baseAuthUrl = process.env["REACT_APP_AUTH_BASE_URL"]

export const getToken = async (): Promise<string | undefined> => {
    let token = localStorage.getItem(Constants.TOKEN) || undefined
    if (!token || isTokenExpired(token)) {
        let refreshToken = localStorage.getItem(Constants.REFRESH_TOKEN) || undefined
        if (!refreshToken || isTokenExpired(refreshToken)) {
            localStorage.removeItem(Constants.REFRESH_TOKEN)
            localStorage.removeItem(Constants.TOKEN)
            return undefined
        } else {
            token = await postRefreshTokenNetwork()
        }
    }
    return token
}