import {useNavigate, useParams} from "react-router-dom"
import {useQuery} from "react-query"
import {getQueryByIdNetwork} from "../../../networks/getNetwork"
import CandidateDelete from "./CandidateDelete"
import CandidateEditButton from "./CandidateEditButton"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import CandidateCard from "../../common/CandidateCard"
import {UserRoles} from "../../../models/Names"
import React from "react";
import {ProgressSpinner} from "primereact/progressspinner";
import usePageAnalytics from "../../../hooks/usePageAnalytics"


const CandidateDetails = () => {

    let {id} = useParams()
    let navigate = useNavigate()
    const {
        isLoading,
        data
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, id], getQueryByIdNetwork, {staleTime: 60000})

    usePageAnalytics('/'+RouteFrontEnd.CANDIDATES+'/'+id, 'CandidateDetails')

    const addExperience = () => {
        navigate(`/${RouteFrontEnd.CANDIDATES}/${id}/${RouteFrontEnd.EXPERIENCES}/new`)
    }

    const header = <div className="font-bold text-l flex relative">
        <div
            onClick={() => navigate('/'+RouteFrontEnd.CANDIDATES, {replace: true})}
            style={{cursor: "pointer"}}
        >
            <i className="pi pi-angle-left text-xl mr-2"></i>
            Candidates
        </div>
        <div className="absolute top-50 bottom-0 right-0 mr-8">
            <br/>
            <span className="mr-4"><CandidateEditButton id={id} role={UserRoles.AGENCY}/></span>
            <CandidateDelete id={id}/>
        </div>
    </div>

    if (isLoading) return <ProgressSpinner/>

    return (
        <CandidateCard
            header={header}
            data={data}
            onClick={(): string => window.location.href = `mailto:${data.email}`}
            addExperience={addExperience}/>
    )
}

export default CandidateDetails