import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getAgencies} from "../../../networks/getNetwork"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import React from "react"
import {ProgressSpinner} from "primereact/progressspinner"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {useNavigate} from "react-router-dom"
import {countryNameForCode} from "../../../utils/CountryFunctions"
import {UserRoles} from "../../../models/Names"
import useRoleStatus from "../../../hooks/useRoleStatus"
import usePageAnalytics from "../../../hooks/usePageAnalytics"

const AgencyList = () => {
    const navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const {isLoading, data, isError} = useQuery([NetworkEndpoints.ADMIN_AGENCIES], getAgencies, {staleTime: 60000})

    usePageAnalytics("/"+RouteFrontEnd.AGENCIES, "Agency List")

    const displayCountry = (rowData: { country: string }) => {
        return countryNameForCode(rowData.country)
    }
    const onRowClick = (event: any): void => {
        navigate(`/${RouteFrontEnd.AGENCIES}/${event.data._id}`)
    }

    if (roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>
    if (isLoading) return <ProgressSpinner/>
    return (
        <div className="grid">
            <div className="col">
                <DataTable
                    selectionMode="single"
                    dataKey="id"
                    value={data}
                    sortMode="multiple"
                    onRowClick={onRowClick}
                >
                    <Column sortable header="Name" field="name"></Column>
                    <Column sortable header="Country" field="country" body={displayCountry}></Column>
                    <Column header="Tax ID" field="taxId" />
                    <Column sortable header="Credits" field="credits" />
                </DataTable>
            </div>
        </div>
    )
}

export default AgencyList