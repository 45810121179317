import {Experience, User} from "../../models/AllModels"
import {Avatar} from "primereact/avatar"
import {avatarImageGenerator, dateShow, getWorkMonthsBetween, getYearsAndMonthsString} from "../../utils/UtilFunctions"
import ExperienceWorkStatusView from "../MainApp/experience/ExperienceWorkStatusView"
import VerificationList from "./VerificationList"
import {Card} from "primereact/card"
import ExperienceEditButton from "../MainApp/experience/ExperienceEditButton"
import ExperienceDelete from "../MainApp/experience/ExperienceDelete"
import AddVerification from "../MainApp/experience/verification/AddVerification"
import {ProgressSpinner} from "primereact/progressspinner"

type ExperienceCardProps = {
    experience: Experience
    candidate: User
    back: () => void
}
const ExperienceCard = (props: ExperienceCardProps) => {
    const {experience, candidate} = props

    const header = <div className='font-bold text-l flex flex-row pt-2 pl-2'>
        <div
            onClick={() => props.back()}
            style={{cursor: "pointer"}}
        >
            <i className="pi pi-angle-left lg:text-xl md:text-base mr-2"></i>
            {candidate?.name}
        </div>
        <div className='ml-2 lg:ml-6 md:ml-2'>
            {!experience?.locked && <span className='mr-2 lg:mr-4 md:mr-4'><ExperienceEditButton id={'' + experience?._id}/></span>}
            {(!!experience?._id && !experience?.locked) && <ExperienceDelete id={'' + experience._id} candidateId={''+props.candidate._id}/>}
        </div>
    </div>

    const footer: JSX.Element = <div className='flex flex-row-reverse flex-wrap pt-2'>
        <div className='flex w-1 '/>
        <AddVerification exp={experience}/>
    </div>

    const effectiveMonths: number = getWorkMonthsBetween(experience.jobStartDate!, experience.jobEndDate!)
    const effectiveMonthsString: string = getYearsAndMonthsString(effectiveMonths)
    if((''+experience._id).length < 2 ) return <ProgressSpinner/>
    return (
        <Card
            header={header}
            className='p-card shadow-3'
        >
            <div className='flex flex-row flex-wrap card-container'>
                <div className='col lg:m-4 md:m-1'>
                    <Avatar image={avatarImageGenerator(candidate._id.toString())} className="lg:p-mr-2 md:p-mr-1" size="large"
                            shape="square"/>
                    <div className='pt-3 flex'>
                        <div className='font-bold pr-2'>Company:</div>
                        {experience.companyName}</div>
                    <div className='pt-3 flex'>
                        <div className='font-bold pr-2'>Position:</div>
                        {experience.jobTitle}</div>
                    <br></br>
                    <div className='pt-3 flex'>
                        <div className='font-bold pr-2'>Duration:</div>
                        {effectiveMonthsString} ({dateShow(Number(experience.jobStartDate))} → {dateShow(Number(experience.jobEndDate))})
                    </div>
                    <ExperienceWorkStatusView
                        jobOngoing={experience.jobOngoing}
                        continuous={experience.continuous}
                        effectiveWorkMonths={experience.effectiveWorkMonths ?? 0}
                        jobFullTime={experience.jobFullTime}
                        contractor={experience.contractor} />
                </div>
                <div className='col lg:m-6 md:m-1 verification-list-container'>
                    <VerificationList experienceId={'' + experience._id}/>
                    {footer}
                </div>
            </div>
        </Card>
    )
}

export default ExperienceCard