import {Experience} from "../../../../models/AllModels"
import {Button} from "primereact/button"
import {useContext, useState} from "react"
import AddVerificationForm from "./AddVerificationForm"
import {useMutation, useQueryClient} from "react-query"
import {postVerificationNetwork} from "../../../../networks/postNetwork"
import {candidateAppContext} from "../../../../pages/MainPageCandidate"
import {refreshOnVerificationListChange} from "../../../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../../../services/analytics/analytics"
import useRoleStatus from "../../../../hooks/useRoleStatus"
import {UserRoles} from "../../../../models/Names"
import {patchSendVerification} from "../../../../networks/patchNetwork"

type Props = {
    exp: Experience
}
const AddVerification = (experience: Props) => {
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const userStatus = useRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN], tempToken)
    const [displayDialog, setDisplayDialog] = useState(false)
    const queryClient = useQueryClient()
    const [insertedVerificationId, setInsertedVerificationId] = useState<string>('')
    const mutateSendVerification = useMutation({
        mutationFn: patchSendVerification,
        onSuccess: () => {
            void refreshOnVerificationListChange(queryClient, candidateData.candidateId, '' + experience.exp._id, tempToken)
            analyticsEvent(AnalyticsEventCategory.SEND_VERIFICATION, {
                role: userStatus.role,
                candidateId: candidateData.candidateId,
                verificationId: ""+insertedVerificationId
            })
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.SEND_VERIFICATION, {
                role: userStatus.role,
                candidateId: candidateData.candidateId,
                verificationId: ""+insertedVerificationId,
                error: "Sending to Referee Failed"
            })
        }
    })
    const mutateVerification = useMutation({mutationFn: postVerificationNetwork,
        onSuccess: (data) => {
            mutateSendVerification.mutate({verificationId: data.verificationId, refereeEmail: data.refereeEmail, tempToken: tempToken})
            void refreshOnVerificationListChange(queryClient, candidateData.candidateId, ''+experience.exp._id, tempToken)
            analyticsEvent(AnalyticsEventCategory.CREATE_VERIFICATION, {
                role: userStatus.role,
                candidateId: candidateData.candidateId,
                experienceId: ""+experience.exp._id,
                agencyId: ""+experience.exp.agencyId,
                verificationId: data.verificationId
            })
        },
        onError: (error) => {
            analyticsEvent(AnalyticsEventCategory.CREATE_VERIFICATION, {
                role: userStatus.role,
                candidateId: candidateData.candidateId,
                experienceId: ""+experience.exp._id,
                agencyId: ""+experience.exp.agencyId,
                error: "Error creating verification"
            })
        }
    })


    const addVerification = async (name: string, email: string) => {
        if(!!tempToken){
            mutateVerification.mutate({experienceId: experience.exp._id, refereeEmail: email.toLowerCase(), refereeName: name, token: tempToken})
        }else {
            mutateVerification.mutate({experienceId: experience.exp._id, refereeEmail: email.toLowerCase(), refereeName: name})
        }
        setDisplayDialog(false)
    }

    const showDialog = () => {
        setDisplayDialog(true)
    }

    const onHide = () => {
        setDisplayDialog(false)
    }


    return (
        <>
            <div className="flex "><Button onClick={showDialog}>Add Referee</Button></div>
            <AddVerificationForm displayDialog={displayDialog} onAdd={addVerification} onCancel={onHide}/>
        </>
    )
}
export default AddVerification