import {getQueryNetwork} from "../../networks/getNetwork"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {useEffect} from "react"
import {ProgressSpinner} from "primereact/progressspinner";

const AgencyInfo = () => {
    const {
        isError,
        isLoading,
        data,
        error
    } = useQuery([NetworkEndpoints.AGENCIES], getQueryNetwork, {staleTime: 60000})

    useEffect((): void => {
        if (!!data) {
            localStorage.setItem('agencyId', data._id)
        }
    }, [error, isError, data])

    if (isLoading) return <ProgressSpinner/>


    if (!!data) {
        return (
            <div>
                <center><strong>{data.name}</strong></center>
                <br/>
                {data.credits && <span> {data.credits} credits</span>}
            </div>
        )
    } else {
        return <div>Agency</div>
    }
}
export default AgencyInfo