import {UserRoles} from "../../../models/Names"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getQueryNetwork} from "../../../networks/getNetwork"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import React, {useEffect, useRef, useState} from "react"
import {ProgressSpinner} from "primereact/progressspinner"
import {Button} from "primereact/button"
import {patchInspectionResult} from "../../../networks/patchNetwork"
import {InspectionResult} from "../../../models/AllModels"
import {refreshOnInspectionListChange} from "../../../utils/RefreshFunctions"
import {Toast} from "primereact/toast"
import {ConfirmDialog} from "primereact/confirmdialog"
import {TabPanel, TabView} from "primereact/tabview"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import InspectionAdditionalInformationDialog from "./InspectionAdditionalInformationDialog"

type InspectionStatus = {
    id: string,
    status: true | false | undefined
}

const InspectionPage = () => {
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const queryClient = useQueryClient()
    const toast = useRef(null)
    const [dialogFlag, setDialogFlag]: [boolean, ((value: boolean) => void)] = useState<boolean>(false)
    const [informationDialogFlag, setInformationDialogFlag]: [boolean, ((value: boolean) => void)] = useState<boolean>(false)
    const [selectedExperienceId, setSelectedExperienceId] = useState<string | null>(null)
    const [inspection, setInspection] = useState<InspectionStatus>({} as InspectionStatus)
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const {
        isLoading,
        isError,
        data
    } = useQuery([NetworkEndpoints.ADMIN_INSPECTION], getQueryNetwork, {staleTime: 60000})
    const doneInspection = useQuery([NetworkEndpoints.ADMIN_INSPECTION_DONE], getQueryNetwork, {staleTime: 60000})
    const inspectionMutation = useMutation({
        mutationFn: patchInspectionResult,
        onSuccess: (data) => {
            showSuccess()
            if(!!inspection.status){
                analyticsEvent(AnalyticsEventCategory.APPROVE_BY_ADMIN, {
                    role: UserRoles.ADMIN,
                    verificationId: data.id
                })
            }else {
                analyticsEvent(AnalyticsEventCategory.REJECT_BY_ADMIN, {
                    role: UserRoles.ADMIN,
                    verificationId: data.id
                })
            }
            void refreshOnInspectionListChange(queryClient)
        }, onError: (error) => {
            showError()
            if(!!inspection.status){
                analyticsEvent(AnalyticsEventCategory.APPROVE_BY_ADMIN, {
                    role: UserRoles.ADMIN,
                    verificationId: data.id,
                    error: "Inspection error"
                })
            }else {
                analyticsEvent(AnalyticsEventCategory.REJECT_BY_ADMIN, {
                    role: UserRoles.ADMIN,
                    verificationId: data.id,
                    error: "Inspection error"
                })
            }
            void refreshOnInspectionListChange(queryClient)
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.INSPECTIONS, 'Inspection page')

    useEffect(() => {
        if (!!inspection.status && !!inspection.id) {
            inspectionMutation.mutate({
                id: inspection.id,
                status: inspection.status
            })
        }
    }, [inspection])

    const adminActionOk = (rowData: InspectionResult) => {
        return <Button type="button" icon="pi pi-thumbs-up"
                       onClick={() => setInspection({id: rowData._id, status: true})}/>
    }
    const adminActionNotOk = (rowData: InspectionResult) => {
        return (
            <>
                <ConfirmDialog
                    header="Rejection"
                    visible={dialogFlag}
                    message="Are you sure you want to reject this verification?"
                    icon="pi pi-exclamation-triangle"
                    acceptClassName="p-button-danger"
                    rejectClassName="p-button-secondary"
                    accept={() => setInspection({id: rowData._id, status: false})}
                    reject={() => setDialogFlag(false)}
                    onHide={() => setDialogFlag(false)}
                />
                <Button
                    className="p-button-danger border-0"
                    type="button"
                    icon="pi pi-thumbs-down"
                    onClick={() => setDialogFlag(true)}
                />
            </>
        )
    }

    const details = (rowData: InspectionResult) => {
       return <>
           {selectedExperienceId === rowData.experienceId && <
               InspectionAdditionalInformationDialog experienceId={rowData.experienceId}
                      candidateId={rowData.candidateId}
                      visible={informationDialogFlag}
                      onHide={() => {
                          setInformationDialogFlag(false)
                            setSelectedExperienceId(null)
                      }}/>
           }
           <Button
               className="p-button border-0"
               type="button"
               icon="pi pi-info-circle"
               onClick={() => {
                   setInformationDialogFlag(!informationDialogFlag)
                     setSelectedExperienceId(rowData.experienceId)
               }}
           />
       </>
    }

    const showSuccess = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Verification accepted',
            life: 3000
        })
    }
    const showError = () => {
        // @ts-ignore
        toast?.current?.show({
            severity: 'error',
            summary: 'Error',
            detail: 'We have some issue with sending decision.',
            life: 3000
        })
    }

    if (roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>
    if (isLoading) return <ProgressSpinner/>
    return (
        <>
            <Toast ref={toast}/>
            <div className="card">
                <TabView  activeIndex={activeTabIndex} onTabChange={(e) => {
                    setInformationDialogFlag(false)
                    setActiveTabIndex(e.index)}}>
                    <TabPanel header="To check">
                        <div className="grid">
                            <div className="col">
                                <DataTable
                                    selectionMode="single"
                                    dataKey="id"
                                    value={data}
                                    sortMode="multiple"
                                    // onRowClick={onRowClick}
                                >
                                    <Column sortable header="Name" field="refereeName"></Column>
                                    <Column sortable header="Status" field="attestationStatus"/>
                                    <Column header="Comment" field="refereeComment"/>
                                    <Column header="Title" field="refereeJobTitle"/>
                                    <Column header="Email" field="refereeEmail"/>
                                    <Column header="Info" body={details}/>
                                    <Column header="" body={adminActionOk}/>
                                    <Column header="" body={adminActionNotOk}/>
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Accepted">
                        <div className="grid">
                            <div className="col">
                                <DataTable
                                    selectionMode="single"
                                    dataKey="id"
                                    value={doneInspection.data}
                                    sortMode="multiple"
                                    // onRowClick={onRowClick}
                                >
                                    <Column sortable header="Name" field="refereeName"></Column>
                                    <Column sortable header="Status" field="attestationStatus"/>
                                    <Column header="Comment" field="refereeComment"/>
                                    <Column header="Title" field="refereeJobTitle"/>
                                    <Column header="Email" field="refereeEmail"/>
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    )
}

export default InspectionPage