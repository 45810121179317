import {Button} from "primereact/button"
import {useMutation, useQueryClient} from "react-query"
import {postTokenRepack} from "../../../networks/postNetwork"
import {useNavigate} from "react-router-dom"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {Constants, UserRoles} from "../../../models/Names"
import {refreshOnCandidateListChange} from "../../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"

type LoginAsAgencyButtonProps = {
    agencyId: string,
    buttonClassName?: string
}
const LoginAsAgencyButton = (props: LoginAsAgencyButtonProps) => {
    const queryClient = useQueryClient()
    const navigation = useNavigate()
    const repackTokenMutation = useMutation({mutationFn: postTokenRepack,
        onSuccess: (data) => {
            localStorage.setItem(Constants.TOKEN, data.repackagedToken)
            void refreshOnCandidateListChange(queryClient)
            analyticsEvent(AnalyticsEventCategory.LOGIN_AS_AGENCY, {
                role: UserRoles.ADMIN,
                agencyId: props.agencyId
            })
            navigation('/'+RouteFrontEnd.CANDIDATES, {replace: true} )
        }})

    const loginAsAgency = () => {
        repackTokenMutation.mutate(props.agencyId)
    }

    return (
        <>
            <Button label='Login as agency' className={props.buttonClassName+" p-button-secondary"} icon="pi pi-building" iconPos="left"
                    onClick={() => loginAsAgency()} />
        </>
    )
}

export default LoginAsAgencyButton