import {Button} from "primereact/button"
import {Dialog} from "primereact/dialog"
import {InputText} from "primereact/inputtext"
import {useFormik} from "formik"
import * as Yup from "yup"

type Props = {
    displayDialog: boolean
    onAdd: (name: string, email: string) => void
    onCancel: () => void
}
const AddVerificationForm = (props: Props) => {

    const formik = useFormik({
        initialValues: {
            name: '',
            email: ''

        },
        onSubmit: (values) => {
            // mutate({...state, name: values.email, email: values.email})
            formik.resetForm()
            props.onAdd(values.name, values.email)
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
        })
    })

    const header = <h5 className="pt-2 pl-2">Referee Details</h5>

    return (
        <>
            <Dialog header={header} visible={props.displayDialog} style={{width: '50vw'}}
                    onHide={() => props.onCancel()}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    formik.handleSubmit(e)
                }}>
                    <span className="p-float-label">
                        <InputText id="name" type="name"
                                   {...formik.getFieldProps('name')}
                                   className="w-full"/>
                        <label htmlFor="name">Name Surname</label>
                        <small id="name-error"
                               className="p-error block">{formik.errors && formik.errors.name}</small>
                    </span>
                    <span className="p-float-label mt-6">
                        <InputText id="email"
                                   {...formik.getFieldProps('email')}
                                   className="w-full"/>
                        <label htmlFor="email">Email</label>
                        <small id="email-error" className="p-error block">{formik.errors && formik.errors.email}</small>
                    </span>
                    <Button type="submit" className="mt-6">Add Referee</Button>
                </form>
            </Dialog>
        </>
    )
}

export default AddVerificationForm