import {useContext} from "react"
import {candidateAppContext} from "../../pages/MainPageCandidate"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork} from "../../networks/getNetwork"
import CandidateCard from "../common/CandidateCard"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {useNavigate} from "react-router-dom"
import CandidateConsentDialog from "./CandidateConsentDialog"
import {ProgressSpinner} from "primereact/progressspinner";

const CandidateContent = () => {
    const {candidateData} = useContext(candidateAppContext)
    const {
        isLoading,
        data
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateData.id], getQueryByTempTokenNetwork, {staleTime: 60000, enabled: !!candidateData.id})
    const navigate = useNavigate()

    const addExperience = () => {
        navigate(`/${RouteFrontEnd.CANDIDATE}/${candidateData.id}/${RouteFrontEnd.EXPERIENCES}/new`)
    }

    const header = <div />


    const isConsentGiven = (!(data?.dataProcessingConsent === "NotGiven") || candidateData.isConsentGiven)

    if (isLoading) return <ProgressSpinner/>

    return (
        <>
            <CandidateConsentDialog token={candidateData.id} isConsentGiven={isConsentGiven}/>
            <CandidateCard header={header} data={data} onClick={() => window.location.href = `mailto:${data.email}`} addExperience={addExperience} />
        </>
    )
}
export default CandidateContent