import {InputText} from "primereact/inputtext"
import {Dropdown} from "primereact/dropdown"
import countries from "./countries.json"
import {Button} from "primereact/button"
import {Card} from "primereact/card"
import {useFormik} from "formik"
import {CountryCode} from "../../models/AllModels"
import * as Yup from "yup"
import {DtoUpdateUser} from "../../models/DtoModels"
import {useEffect, useState} from "react"
import {countryForCode} from "../../utils/CountryFunctions"
import useRoleStatus from "../../hooks/useRoleStatus"
import {UserRoles} from "../../models/Names"

type UpdateCandidateFormCardProps = {
    header: JSX.Element,
    initialValues: DtoUpdateUser,
    onUpdate: (data: DtoUpdateUser) => void
}
const UpdateCandidateFormCard = (props: UpdateCandidateFormCardProps) => {
    const roleStatus = useRoleStatus([UserRoles.ADMIN, UserRoles.CANDIDATE, UserRoles.AGENCY])

    const [state, setState] = useState<DtoUpdateUser>({
        candidateId: props.initialValues.candidateId,
        name: props.initialValues.name,
        email: props.initialValues.email,
        country: props.initialValues.country,
        position: props.initialValues.position
    } as DtoUpdateUser)

    const adminOrAgency = roleStatus.role === UserRoles.ADMIN || roleStatus.role === UserRoles.AGENCY

    useEffect(() => {
        if (props.initialValues.candidateId) {
            const receiveCountry: CountryCode | undefined = countryForCode(props.initialValues.country ?? "RS")
            console.log("Received position", props.initialValues.position)
                // @ts-ignore
            formik.setValues({
                    ...state,
                    country: receiveCountry!,
                    position: props.initialValues.position,
                })
            }
    }, [props.initialValues.candidateId])

    const formik = useFormik({
        initialValues: {
            ...state,
            candidateId: props.initialValues.candidateId,
            name: props.initialValues.name,
            email: props.initialValues.email,
            country: countryForCode(props.initialValues.country ?? "RS") as CountryCode,
            position: props.initialValues.position
        },
        onSubmit: (values) => {
            console.log("Pre ON UPDATE", values)
            if (!!state.candidateId) {
                console.log("ON UPDATE")
                props.onUpdate({
                    ...values,
                    candidateId: props.initialValues.candidateId,
                    name: values.name,
                    email: values.email,
                    country: values.country.Code,
                    position: values.position
                })
                formik.resetForm()
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
            country: Yup.object().required('Required'),
        })
    })

    console.log("Role", roleStatus.role)

    return (
        <Card header={props.header} className="align-items-center w-4 mt-4 shadow-3">
            <form onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit(e)
            }}>
                    <span className="p-float-label">
                        <InputText id="name"
                                   {...formik.getFieldProps("name")}
                                   className="w-full"/>
                        <label htmlFor="name">Candidate Name</label>
                        <small id="name-error"
                               className="p-error block">{formik.errors && formik.errors.name}</small>
                    </span>
                <span className="p-float-label mt-6">
                        <InputText id="email"
                                   {...formik.getFieldProps("email")}
                                   className="w-full"/>
                        <label htmlFor="email">Candidate Email</label>
                        <small id="email-error"
                               className="p-error block">{formik.errors && formik.errors.email}</small>
                    </span>
                <span className="p-float-label mt-3">
                        <Dropdown className="mt-6" id="country" options={countries}
                                  {...formik.getFieldProps("country")} optionLabel="Name"
                                  filter showClear filterBy="Name" placeholder="Select a Country"/>
                    {/*<label htmlFor="country">Candidate Country</label>*/}
                    <small id="country-error"
                           className="p-error block">{formik.errors && formik.errors.country?.Name}</small>
                    </span>
                { !!adminOrAgency && <span className="p-float-label mt-6">
                        <InputText id="position"
                                   {...formik.getFieldProps("position")}
                                   className="w-full"/>
                        <label htmlFor="position">Position</label>
                    </span>}
                <Button type="submit" className="mt-6">Update Candidate</Button>
            </form>
        </Card>
    )
}

export default UpdateCandidateFormCard