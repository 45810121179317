import {useNavigate} from "react-router-dom"
import {useMutation, useQueryClient} from "react-query"
import {deleteAgency} from "../../../networks/deleteNetwork"
import {refreshOnAgencyListChange} from "../../../utils/RefreshFunctions"
import {useState} from "react"
import {ConfirmDialog} from "primereact/confirmdialog"
import {Button} from "primereact/button"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import {UserRoles} from "../../../models/Names"

type AgencyDeleteProps = {
    id: string|undefined
}
const AgencyDelete = (props: AgencyDeleteProps) => {
    let navigate = useNavigate()
    const queryClient = useQueryClient()
    const deletingMutation = useMutation(deleteAgency, {
        onSuccess: (data) => {
            void refreshOnAgencyListChange(queryClient)
            analyticsEvent(AnalyticsEventCategory.DELETE_AGENCY, {
                role: UserRoles.ADMIN,
                agencyId: props.id
            })
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.DELETE_AGENCY, {
                role: UserRoles.ADMIN,
                agencyId: props.id,
                error: "Deleting Agency Failed"
            })
        }
    })
    const [dialogVisible, setDialogVisible] = useState(false)

    const handleDeleteAgency = () => {
        if (props.id !== undefined){

            deletingMutation.mutate(props?.id)
            navigate(-1)
        }
    }
    const handleClick = () => {
        setDialogVisible(true)
    }
    return (
        <>
            <ConfirmDialog header="Confirm" visible={dialogVisible}
                           message="Are you sure you want to delete this candidate?"
                           icon="pi pi-exclamation-triangle"
                           acceptClassName="p-button-danger"
                           rejectClassName="p-button-secondary"
                           accept={() => handleDeleteAgency()}
                           reject={() => {setDialogVisible(false)}}
                           onHide={() => {setDialogVisible(false)}} />
            <Button onClick={handleClick} className='bg-red-300 p-2 border-0' >
                <i className="pi pi-trash" style={{'fontSize': '1em'}}></i>
            </Button>
        </>
    )
}

export default AgencyDelete