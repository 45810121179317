import AgencyInfo from "./AgencyInfo"
import LogoutButton from "./LogoutButton"
import React from "react"

type MainAppMenuEndProps = {
    isAdmin: boolean
}
const MainAppMenuEnd = (props: MainAppMenuEndProps) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '1rem'
        }}
             className="flex flex-row card-container">
            {!props.isAdmin && <AgencyInfo/>}
            <LogoutButton/>
        </div>
    )
}

export default MainAppMenuEnd