import {useNavigate} from "react-router-dom"
import {useFormik} from "formik"
import {AgencyModel, CountryCode} from "../../../models/AllModels"
import * as Yup from "yup"
import {Card} from "primereact/card"
import {InputText} from "primereact/inputtext"
import {Dropdown} from "primereact/dropdown"
import countries from "../../common/countries.json"
import {Button} from "primereact/button"
import {useEffect, useState} from "react"
import {countryForCode} from "../../../utils/CountryFunctions"
import {DtoAgency} from "../../../models/DtoModels"

type AgencyFormCardProps = {
    initialValues: AgencyModel,
    submit: (values: { name: string, country: string, taxId: string }) => void,
}
const AgencyFormCard = (props: AgencyFormCardProps) => {
    const navigate = useNavigate()

    const [state, setState] = useState<DtoAgency>({
        agencyId: props.initialValues._id,
        name: props.initialValues.name,
        taxId: props.initialValues.taxId,
        country: props.initialValues.country,
    } as DtoAgency)


    useEffect(() => {
        if (!!props.initialValues._id) {
            const receiveCountry: CountryCode | undefined = countryForCode(props.initialValues.country)
            formik.setValues({
                ...state,
                country: receiveCountry ?? {} as CountryCode
            })
        }
    }, [props.initialValues._id])

    const formik = useFormik({
        initialValues: {
            name: props.initialValues.name,
            country: {} as CountryCode,
            taxId: '',
            isInvited: true
        },
        onSubmit: (values: any): void => {
            props.submit({
                ...values,
                name: formik.values.name,
                country: formik.values.country.Code,
                taxId: formik.values.taxId,
                isInvited: formik.values.isInvited
            })
            formik.resetForm()
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            country: Yup.object().required('Required'),
        })
    } as any) as any

    const disabledSubmit = () => {
        return !formik.values.country || !formik.values.name
    }
    const newOrUpdate = !!props.initialValues._id ? "Update Agency" : "Add Agency"

    const header = <div className="font-bold text-l flex relative, pt-2">
        <div onClick={() => navigate(-1)} style={{cursor: "pointer"}}>
            <i className="pi pi-angle-left text-l mr-2"></i>
            <span className="pt-2 pl-2 text-l font-bold">Agencies</span></div>
    </div>

    return (
        <Card header={header} className="align-items-center w-4 mt-4 shadow-3">
            <form onSubmit={(e: any): void => {
                e.preventDefault()
                formik.handleSubmit(e)
            }}>
                <span className="p-float-label mt-6">
                        <InputText id="name"
                                   {...formik.getFieldProps('name')}
                                   className="w-full"/>
                        <label htmlFor="name">Agency Name</label>
                        <small id="name-error" className="p-error block">{formik.errors && formik.errors.name}</small>
                    </span>

                <span className="mt-6">
                        <Dropdown
                            className="mt-6"
                            id="country" options={countries}{...formik.getFieldProps('country')}
                            optionLabel="Name"
                            filter showClear filterBy="Name"
                            placeholder="Select a Country"
                        />
                    <small id="country-error"
                           className="p-error block">{formik.errors && formik.errors.country?.Name}</small>
                </span>
                <span className="p-float-label mt-6">
                        <InputText id="taxId"
                                   {...formik.getFieldProps('taxId')}
                                   className="w-full"/>
                        <label htmlFor="taxId">Tax ID</label>
                    </span>
                <Button type="submit" className="mt-6" disabled={disabledSubmit()}>{newOrUpdate}</Button>
            </form>
        </Card>
    )

}

export default AgencyFormCard