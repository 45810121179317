import {Button} from "primereact/button"
import {useRef, useState} from "react"
import DepositBalanceForm from "./DepositBalanceForm"
import {useMutation, useQueryClient} from "react-query"
import {postBalance} from "../../../networks/postNetwork"
import {refreshOnAgencyChange} from "../../../utils/RefreshFunctions"
import {Toast} from "primereact/toast"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import {UserRoles} from "../../../models/Names"

type DepositBalanceButtonProps = {
    agencyId: string,
    buttonClassName?: string
}
const DepositBalance = (props: DepositBalanceButtonProps) => {
    const queryClient = useQueryClient()
    const [displayDialog, setDisplayDialog] = useState(false)
    const balanceMutation = useMutation({
        mutationFn: postBalance,
        onSuccess: (data) => {
            setDisplayDialog(false)
            void refreshOnAgencyChange(queryClient)
            showSuccess()
            analyticsEvent(AnalyticsEventCategory.CREDIT_ADD, {
                role: UserRoles.ADMIN,
                agencyId: props.agencyId
            })
        }, onError: (error) => {
            console.log('error', error)
            analyticsEvent(AnalyticsEventCategory.CREDIT_ADD, {
                role: UserRoles.ADMIN,
                agencyId: props.agencyId,
                error: "Adding Balance Failed"
            })
        }
    })
    const toast = useRef(null) as any


    const openDepositBalanceDialog = () => {
        setDisplayDialog(true)
    }

    const addBalance = async (creditsCount: number, amountPaid: number, currency: string) => {
        balanceMutation.mutate({
            agencyId: props.agencyId,
            creditsCount: creditsCount,
            amountPaid: amountPaid,
            currency: currency
        })
        setDisplayDialog(false)
    }

    const onHide = () => {
        setDisplayDialog(false)
    }

    const showSuccess = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully added balance',
            life: 3000
        })
    }

    return (
        <>
            <Toast ref={toast}/>
            <DepositBalanceForm displayDialog={displayDialog} onAdd={addBalance} onCancel={onHide}/>
            <Button className={props.buttonClassName + ' bg-green-400'} label="Deposit balance" icon="pi pi-dollar"
                    iconPos="left"
                    onClick={() => openDepositBalanceDialog()}/>
        </>
    )
}
export default DepositBalance