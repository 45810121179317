import {Avatar} from "primereact/avatar"
import {Experience, User} from "../../models/AllModels"
import {avatarImageGenerator, dateShow} from "../../utils/UtilFunctions"

type UserExperienceProps = {
    user: User | undefined,
    experience: Experience | undefined
}
const UserExperienceDesc = ({user, experience}: UserExperienceProps) => {
    return(
        <div className='flex'>
            <div className='col-3'>
                <Avatar image={avatarImageGenerator(user!._id.toString())} className="p-mr-4" size="large" shape="square" />
            </div>

            <div className='col-6'>
                <div className='md:text-m lg:text-xl pb-2 text-blue-800'>{user?.name}</div>
                <div className='md:text-m lg:text-xl pb-2 text-gray-500'>{experience?.jobTitle}</div>
                <div>Worked for company: <b>{experience?.companyName}</b></div>
            </div>

            <div className='col-3 '>
                <div className='md:text-m lg:text-xl pb-2'>From: <span className='font-bold md:text-m lg:text-xl'>{dateShow(Number(experience?.jobStartDate))}</span></div>
                <div className='md:text-m lg:text-xl'>Until: <span className='font-bold md:text-m lg:text-xl'>{dateShow(Number(experience?.jobEndDate))}</span></div>
            </div>
        </div>
    )
}

export default UserExperienceDesc