import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import React, {useEffect, useRef, useState} from "react"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getAdminUsers, getPendingInvites} from "../../../networks/getNetwork"
import {ProgressSpinner} from "primereact/progressspinner"
import {Toast} from "primereact/toast"
import InviteFormCard from "../admin/InviteFormCard"
import {Button} from "primereact/button"
import {DataTable} from "primereact/datatable"
import {AdminUser} from "../../../models/AllModels"
import {Column} from "primereact/column"
import {useParams} from "react-router-dom"
import {patchAgencyUserInvite, patchResetPasswordInvite} from "../../../networks/patchNetwork"
import {dateShow} from "../../../utils/UtilFunctions"
import {refreshOnAgencyPendingList} from "../../../utils/RefreshFunctions"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"

const AgencyUsersPage = () => {
    let {id} = useParams()
    const queryClient = useQueryClient()
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const toast = useRef(null)
    const [displayDialog, setDisplayDialog] = useState(false)
    const [agencyUsers, setAgencyUsers] = useState<AdminUser[]>([])
    const {isLoading, isError, data} = useQuery([NetworkEndpoints.USERS], getAdminUsers, {staleTime: 60000})
    const inviteData = useQuery([NetworkEndpoints.USER_PENDING_INVITE, id], getPendingInvites, {staleTime: 60000})
    const inviteMutation = useMutation({
        mutationFn: patchAgencyUserInvite,
        onSuccess: () => {
            void refreshOnAgencyPendingList(queryClient, id)
            analyticsEvent(AnalyticsEventCategory.INVITE_AGENCY_USER, {
                role: roleStatus.role,
                agencyId: id
            })
            showSuccess('User is successfully invited')
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.INVITE_AGENCY_USER, {
                role: roleStatus.role,
                agencyId: id,
                error: "Invite user failed"
            })
        }
    })
    const passwordResetMutation = useMutation({
        mutationFn: patchResetPasswordInvite,
        onSuccess: () => {
            analyticsEvent(AnalyticsEventCategory.RESET_PASSWORD_SENT, {
                role: roleStatus.role,
                agencyId: id
            })
            showSuccess('Password reset email is sent')
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.RESET_PASSWORD_SENT, {
                role: roleStatus.role,
                agencyId: id,
                error: "Send password reset email failed"
            })
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.AGENCY_USERS+'/'+id, 'Agency users')

    const inviteUser = async (email: string) => {
        inviteMutation.mutate({email: email, agencyId: id})
        setDisplayDialog(false)
    }

    const onHide = () => {
        setDisplayDialog(false)
    }
    const onDialogShow = () => {
        setDisplayDialog(true)
    }

    useEffect(() => {
        if (!!data) {
            setAgencyUsers(data.filter((u: AdminUser) => u.role === UserRoles.AGENCY && u.agencyId === id))
        }
    }, [data])

    const passwordResetAction = (rowData: AdminUser) => {
        return <Button type="button" label="Reset" className="p-button-danger"
                       onClick={() => passwordResetMutation.mutate({email: rowData.email})}/>
    }

    const showLastLogin = (rowData: { lastLogin: number }) => {
        if (!!rowData.lastLogin) {
            return dateShow(rowData.lastLogin)
        }
    }
    const showSentAt = (rowData: { sentAt: number }) => {
        if (!!rowData.sentAt) {
            return dateShow(rowData.sentAt)
        }
    }

    const showSuccess = (message: String) => {
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000
        })
    }

    if (roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>
    if (isLoading || inviteData.isLoading) return <ProgressSpinner/>
    if (isError) return <div>Error</div>
    return (
        <>
            <Toast ref={toast}/>
            <InviteFormCard displayDialog={displayDialog} onAdd={inviteUser} onCancel={onHide}/>
            <div className="flex flex-row align-items-center">
                <h1 className="flex ">Agency users</h1>
                <Button className="flex bg-green-400 h-3rem ml-3 border-0" label="Invite user" icon="pi pi-plus"
                        onClick={onDialogShow}/>
            </div>
            <div className="grid">
                <div className="col">
                    <DataTable
                        // selectionMode="single"
                        dataKey="userId"
                        value={agencyUsers}
                        sortMode="multiple"
                        // onRowClick={onRowClick}
                    >
                        <Column sortable header="Name" field="name"></Column>
                        <Column header="Email" field="email"/>
                        <Column header="Last login" body={showLastLogin}/>
                        <Column sortable header="Role" field="role"/>
                        <Column header="Password reste" body={passwordResetAction}/>
                    </DataTable>
                </div>
            </div>
            <div className="flex flex-row align-items-center">
                <h1 className="flex ">Agency pending invites</h1>
            </div>
            <DataTable
                // selectionMode="single"
                dataKey="userId"
                value={inviteData.data}
                sortMode="multiple"
                // onRowClick={onRowClick}
            >
                <Column header="Email" field="email"/>
                <Column header="Sent at" body={showSentAt}/>
            </DataTable>
        </>
    )
}
export default AgencyUsersPage