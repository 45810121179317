import {useNavigate, useParams} from "react-router-dom"
import {useFormik} from "formik"
import * as Yup from "yup"
import {Card} from "primereact/card"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import logo from "../resources/LogoSymbolOnly.png"
import React from "react"
import {useMutation} from "react-query"
import {postRegistration} from "../networks/postNetwork"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import usePageAnalytics from "../hooks/usePageAnalytics"
import {analyticsEvent, AnalyticsEventCategory} from "../services/analytics/analytics"
import {UserRoles} from "../models/Names"

const RegistrationPage = () => {
    let {token} = useParams()
    const navigate = useNavigate()
    const registrationInviteMutation = useMutation({
        mutationFn: postRegistration,
        onSuccess: () => {
            analyticsEvent(AnalyticsEventCategory.REGISTER, {
                role: UserRoles.UNKNOWN //TODO: Get role from token
            })
            navigate(RouteFrontEnd.LOGIN)
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.REGISTER, {
                role: UserRoles.UNKNOWN,
                error: "Registration failed"
            })
        }
    })

    usePageAnalytics("/"+RouteFrontEnd.REGISTRATION, "Registration")

    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/

    const formik = useFormik({
        initialValues: {
            name: '',
            password: ''
        },
        onSubmit: (values) => {
            if (!!token) {
                registrationInviteMutation.mutate({
                    name: values.name,
                    password: values.password,
                    token: token
                })
                formik.resetForm()
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            password: Yup.string().required('No password provided.')
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(passwordRules, {message: "Please create a stronger password"})
        })
    })

    return (
        <Card className="p-card pt-3 pl-3 m-4 shadow-3">
            <div className="col-12 text-2xl font-bold text-left mb-5">
                <div className="flex-row">
                    <img src={logo} alt="logo" height="20" width="20" className="fex mr-3 mb-2 w-4rem h-4rem"/>
                    <h3 className='flex'>Register your account</h3>
                    <p className="text-sm text-gray-600">Hi, welcome to Signify! </p>
                    <p className="text-xs text-gray-600 m-0">We're delighted to have you on board. To get started, please register your account. Enter the email address you provided to our representative, and create a complex password.</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <span className="p-float-label mb-5">
                    <InputText id="name" type="text" size={30}
                               {...formik.getFieldProps('name')}
                               className="w-full"/>
                        <label htmlFor="name">Enter your email address</label>
                        <small id="name-error"
                               className="p-error block">{formik.values.name && formik.errors.name}</small>
                    </span>
                <span className="p-float-label mb-4 text-gray-600 text-xs">
                    Create a complex password by using a mix of uppercase and lowercase letters, and incorporating numbers and symbols
                </span>
                <span className="p-float-label">
                        <InputText id="password" type="password" size={30}
                                   {...formik.getFieldProps('password')}
                                   className="w-full"/>
                        <label htmlFor="password">Password</label>
                        <small id="password-error"
                               className="p-error block">{formik.values.password && formik.errors.password}</small>
                    </span>
                <Button type="submit" className="mt-6 mb-4">Submit</Button>
                <span className="p-float-label text-gray-600 text-xs">
                    Once you have registered your account, the login tab will automatically open. You can then enter your email and the password you created to log in.
                </span>
            </form>
        </Card>
    )
}
export default RegistrationPage