
import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {getToken} from "../utils/NetworkUtil"
import {DtoAgency, DtoExperience, DtoUpdateUser, DtoUser} from "../models/DtoModels"

const putNetwork = async <T>(myUrl: string, obj: T, tempToken?: string) => {
    const baseUrl = process.env["REACT_APP_URL_BASE"]
    var token = await getToken()

    if(!!tempToken) {
        token = tempToken
    }

    const response = await fetch(`${baseUrl}/${myUrl}`, {
        method: 'PUT',
        body: JSON.stringify(obj),
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })

    if (!response.ok) {
        throw new Error(response.statusText)
    }

    return await response.json()
}

export const putAgencyNetwork = async (dto: DtoAgency) => {
    let agencyDto: DtoAgency = {
        name: dto.name,
    }
    if (dto.taxId !== undefined && dto.taxId !== null) {
        agencyDto.taxId = dto.taxId
    }
    if (dto.country !== undefined && dto.country !== null) {
        agencyDto.country = dto.country
    }
    return putNetwork(NetworkEndpoints.AGENCIES+"/"+dto.agencyId, agencyDto)
}

export const putCandidateNetwork = async (dto: DtoUpdateUser) => {

    const userDto: DtoUser = {
        name: dto.name,
        email: dto.email,
        country: dto.country,
        contactForJobOffers: dto.contactForJobOffers,
        position: dto.position,
    }
    if(!!dto.token){
        const url = NetworkEndpoints.CANDIDATE
        return putNetwork(url, userDto, dto.token)
    }else{
        const url = NetworkEndpoints.CANDIDATE+"/"+dto.candidateId
        return putNetwork(url, userDto)
    }
}

export const putExperienceNetwork = async (dto: DtoExperience) => {
    const url = NetworkEndpoints.EXPERIENCES+"/"+dto.experienceId
    const sentDto = {
        companyName: dto.companyName,
        jobTitle: dto.jobTitle,
        jobStartDate: dto.jobStartDate,
        jobEndDate: dto.jobEndDate,
        jobOngoing: dto.jobOngoing,
        jobFullTime: dto.jobFullTime,
        continuous: dto.continuous,
        contractor: dto.contractor,
        effectiveWorkMonths: dto.effectiveWorkMonths
    }
    return putNetwork(url, sentDto, dto.token)
}

