import {Experience} from "../../../models/AllModels"
import {DataScroller} from "primereact/datascroller"
import ExperienceItem from "./ExperienceItem"

type RecruitmentEventProps = {
    exp: Experience[] | undefined
}
const ExperienceCustomList = (props: RecruitmentEventProps) => {

    const itemTemplate = (data: Experience) => {
        return <ExperienceItem exp={data}/>
    }

    return (
        <div className='verification-list-container'>
            <DataScroller
                value={props.exp}
                itemTemplate={itemTemplate}
                rows={100} inline scrollHeight="350px"
                header="Experiences"
            />
        </div>
    )
}
export default ExperienceCustomList