import md5 from "md5"
import jwtDecode from "jwt-decode"
export class UtilFunctions{

    static displayCheckStatus = (status: string) =>{
        switch (status) {
            case "Draft":
                return 'bg-indigo-600'
            case "Awaiting":
                return 'bg-orange-700'
            case "Sent":
                return 'bg-orange-700'
            case "Accepted":
                return ['bg-bluegray-800 text-xl text-white-alpha-80 p-2 font-bold', 'Confirm']
            case "Rejected":
                return ['bg-red-600 text-xl text-white-alpha-80 p-2 font-bold', 'Deny']
            case "Submitted":
                return ['bg-orange-700 text-xl text-white-alpha-80 p-2 font-bold' , 'Submitted']
            case "Signed":
                return ['bg-bluegray-800 text-xl text-white-alpha-80 p-2 font-bold', 'Signed']
            case "Timeout":
                return ['bg-red-600 text-xl text-white-alpha-80 p-2 font-bold', 'Time out']
            default:
                return ['bg-gray-800 text-xl text-white-alpha-80 p-2 font-bold', 'Default']

        }
    }
}

export const candidateColor = (status?: string) =>{
    if(!status) return ''
    switch (status) {
        case "Awaiting":
            return 'text-orange-600'
        case "Rejected":
            return 'text-red-600'
        case "Confirmed":
            return 'text-green-600'
        default: return ''
    }
}

export const avatarImageGenerator = (name: string) => {
    const size = 50
    const digest = md5(name)
    return  'https://www.gravatar.com/avatar/'+digest+'?d=identicon&s='+size
}

export const dateMilitaryFormat = (date: Date): number => {
    // const tempDay = date.getDate()
    // const day: string = tempDay.toString().length === 1 ? '0' + tempDay.toString() : tempDay.toString()
    const tempMonth = date.getMonth() + 1
    const month: string = tempMonth.toString().length === 1 ? '0' + tempMonth.toString() : tempMonth.toString()

    const year: number = date.getFullYear()

    const numberDate: number = parseInt(`${year}${month}`)
    // return `${year}${month}${day}`
    return numberDate
}

export const dateParse = (date: number) => {
    if(date.toString().length>6){
        return new Date(date)
    }
    return new Date(Date.parse(''+date.toString().slice(0,4)+'-'+date.toString().slice(-2)))
}

export const dateShow = (date: number) => {
    if(date.toString().length>6){
        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long',day: '2-digit'}).format(date)
    }
    const dateFromNumb = Date.parse(''+date.toString().slice(0,4)+'-'+date.toString().slice(-2))
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long'}).format(dateFromNumb)
}

export const getWorkMonthsBetween = (_start: string, _end: string) => {
    let start: number = Number(_start)
    let end: number = Number(_end)
    const startDate = new Date(Math.floor(start / 100), (start % 100) - 1, 1)
    const endDate = new Date(Math.floor(end / 100), (end % 100) - 1, 1)

    const diffInMonths: number = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth())

    return diffInMonths + 1
}

export const getYearsAndMonthsString = (effectiveMonths: number): string => {
    const years = Math.floor(effectiveMonths / 12)
    const months = effectiveMonths % 12

    const yearString = years > 1 ? `${years} years` : years === 1 ? '1 year' : ''
    const monthString = months > 1 ? `${months} months` : months === 1 ? '1 month' : ''

    if (yearString && monthString) {
        return `${yearString} ${monthString}`
    } else {
        return `${yearString}${monthString}`
    }
}

export const jwtDecodeId = (token: string | undefined) => {
    if(!token) return ''
    const tokenDecoded:{permissions: {userId: string}} = jwtDecode(token)
    return tokenDecoded.permissions.userId
}

export const sleep = async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}