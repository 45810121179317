import {useFormik} from "formik"
import * as Yup from "yup"
import {Dialog} from "primereact/dialog"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"

type DepositBalanceFormProps = {
    displayDialog: boolean
    onAdd: (creditsCount: number, amountPaid: number, currency: string) => void
    onCancel: () => void
}
const DepositBalanceForm = (props: DepositBalanceFormProps) => {
    const formik = useFormik({
        initialValues: {
            creditsCount: 0,
            amountPaid: 0,
            currency: ''
        },
        onSubmit: (values) => {
            props.onAdd(values.creditsCount, values.amountPaid, values.currency)
            formik.resetForm()
        },
        validationSchema: Yup.object({
            creditsCount: Yup.number().required('Required'),
            amountPaid: Yup.number().required('Required'),
            currency: Yup.string().required('Required'),
        })
    })

    const header = <h5 className="pt-2 pl-2">Add balance to the Agency</h5>

    return (
        <>
            <Dialog header={header} visible={props.displayDialog} style={{width: '50vw'}}
                    onHide={() => props.onCancel()}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    formik.handleSubmit(e)
                }}>
                    <span className="p-float-label">Help us confirm this experience.</span>
                    <span className="p-float-label mt-6">
                        <InputText id="creditsCount" type="name"
                                   {...formik.getFieldProps('creditsCount')}
                                   className="w-full"/>
                        <label htmlFor="creditsCount">Credits amount</label>
                        <small id="creditsCount-error"
                               className="p-error block">{formik.errors && formik.errors.creditsCount}</small>
                    </span>
                    <span className="p-float-label mt-6">
                        <InputText id="amountPaid" type="name"
                                   {...formik.getFieldProps('amountPaid')}
                                   className="w-full"/>
                        <label htmlFor="amountPaid">Amount paid</label>
                        <small id="amountPaid-error"
                               className="p-error block">{formik.errors && formik.errors.amountPaid}</small>
                    </span>
                    <span className="p-float-label mt-6">
                        <InputText id="currency"
                                   {...formik.getFieldProps('currency')}
                                   className="w-full"/>
                        <label htmlFor="currency">Currency</label>
                        <small id="currency-error" className="p-error block">{formik.errors && formik.errors.currency}</small>
                    </span>
                    <Button type="submit" className="mt-6">Add Balance</Button>
                </form>
            </Dialog>
        </>
    )
}
export default DepositBalanceForm