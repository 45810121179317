import {InputText} from "primereact/inputtext"
import {Dropdown} from "primereact/dropdown"
import countries from "./countries.json"
import {Button} from "primereact/button"
import {Card} from "primereact/card"
import {useFormik} from "formik"
import {CountryCode} from "../../models/AllModels"
import * as Yup from "yup"
import {NavigateFunction, useNavigate} from "react-router-dom"
import {Checkbox} from "primereact/checkbox"
import {Tooltip} from "primereact/tooltip"

type NewCandidateFormCardProps = {
    submit: (values: { name: string, email: string, country: string, position: string, isInvited: boolean }) => void,
}
const NewCandidateFormCard = (props: NewCandidateFormCardProps) => {
    const navigate: NavigateFunction = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            country: {} as CountryCode,
            position: '',
            isInvited: true
        },
        onSubmit: (values: any): void => {
            props.submit({
                ...values,
                name: formik.values.name,
                email: formik.values.email,
                country: formik.values.country.Code,
                position: formik.values.position,
                isInvited: formik.values.isInvited
            })
            formik.resetForm()
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            country: Yup.object().required('Required'),
        })
    } as any) as any

    const disabledSubmit = () => {
        return !formik.values.country || !formik.values.name || !formik.values.email
    }

    const header = <div className="font-bold text-l flex relative, pt-2">
        <div onClick={() => navigate(-1)} style={{cursor: "pointer"}}>
            <i className="pi pi-angle-left text-l mr-2"></i>
            <span className="pt-2 pl-2 text-l font-bold">New Candidate</span></div>
    </div>

    return (
        <Card header={header} className="align-items-center w-4 mt-4 shadow-3">
            <form onSubmit={(e: any): void => {
                e.preventDefault()
                formik.handleSubmit(e)
            }}>
                <Tooltip target='.inviteCheckbox' position='mouse'>
                    'Invite Candidate' is automatically selected. When you add a candidate, they'll receive an email with a link to enter their work experience and referees. Deselect this option if you don't want the invitation sent.
                </Tooltip>
                <span className="inviteCheckbox">
                    <Checkbox className="mr-2" inputId="invited"
                              checked={formik.values.isInvited} {...formik.getFieldProps('isInvited')} />
                    <label className="" htmlFor="invited">Invite candidate</label>
                </span>
                <span className="p-float-label mt-6">
                        <InputText id="name"
                                   {...formik.getFieldProps('name')}
                                   className="w-full"/>
                        <label htmlFor="name">Candidate Name</label>
                        <small id="name-error" className="p-error block">{formik.errors && formik.errors.name}</small>
                    </span>
                <span className="p-float-label mt-6">
                        <InputText id="email"
                                   {...formik.getFieldProps('email')}
                                   className="w-full"/>
                        <label htmlFor="email">Candidate Email</label>
                        <small id="email-error" className="p-error block">{formik.errors && formik.errors.email}</small>
                    </span>
                <span className="mt-6">
                        <Dropdown
                            className="mt-6"
                            id="country" options={countries}{...formik.getFieldProps('country')}
                            optionLabel="Name"
                            filter showClear filterBy="Name"
                            placeholder="Select a Country"
                        />
                    <small id="country-error"
                           className="p-error block">{formik.errors && formik.errors.country?.Name}</small>
                </span>
                <span className="p-float-label mt-6">
                        <InputText id="position"
                                   {...formik.getFieldProps("position")}
                                   className="w-full"/>
                        <label htmlFor="position">Position</label>
                    </span>
                <Button type="submit" className="mt-6" disabled={disabledSubmit()}>Add Candidate</Button>
            </form>
        </Card>
    )
}
export default NewCandidateFormCard