import {candidateAppContext} from "../../pages/MainPageCandidate"
import {useContext, useRef} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork} from "../../networks/getNetwork"
import {Experience} from "../../models/AllModels"
import {putExperienceNetwork} from "../../networks/putNetwork"
import {DtoUpdateExperience} from "../../models/DtoModels"
import {dateMilitaryFormat, dateParse} from "../../utils/UtilFunctions"
import {Toast} from "primereact/toast"
import EditExperienceCard from "../common/EditExperienceCard"
import {ProgressSpinner} from "primereact/progressspinner"
import {refreshOnExperienceChange} from "../../utils/RefreshFunctions"
import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import {UserRoles} from "../../models/Names"

const CandidateExperienceUpdate = () => {
    let {experienceId} = useParams()
    let navigate = useNavigate()
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const {
        isLoading,
        data
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateData.id], getQueryByTempTokenNetwork, {
        staleTime: 60000,
        enabled: !!candidateData.id
    })
    const experience = data?.experiences?.filter((e: Experience) => ('' + e._id) === experienceId).pop()
    const toast = useRef(null)
    const queryClient = useQueryClient()

    const {mutate} = useMutation({
        mutationFn: putExperienceNetwork,
        onSuccess: () => {
            refreshOnExperienceChange(queryClient, candidateData.id, experienceId) //Maybe it should use .then for navigate
            analyticsEvent(AnalyticsEventCategory.UPDATE_EXPERIENCE, {
                role: UserRoles.CANDIDATE,
                candidateId: candidateData.candidateId,
                experienceId: experienceId
            })
            navigate(-1)
        },
        onError: () => {
            showError()
            analyticsEvent(AnalyticsEventCategory.UPDATE_EXPERIENCE, {
                role: UserRoles.CANDIDATE,
                candidateId: candidateData.candidateId,
                experienceId: experienceId,
                error: "Error updating experience"
            })
        }
    })

    usePageAnalytics("/"+RouteFrontEnd.CANDIDATE+"/"+candidateData.candidateId+"/experiences/"+experienceId+"/edit", "Candidate Experience Update")

    const updateExperience = (values: DtoUpdateExperience) => {
        const startD: number = dateMilitaryFormat(new Date(values.jobStartDate))
        const endD: number = dateMilitaryFormat(new Date(values.jobEndDate))
        const effectiveMonths = values.continuous ? undefined : values.effectiveWorkMonths
        mutate({
            experienceId: values.experienceId,
            candidateId: values.candidateId,
            companyName: values.companyName,
            jobTitle: values.jobTitle,
            jobStartDate: startD,
            jobEndDate: endD,
            jobOngoing: values.jobOngoing,
            jobFullTime: values.jobFullTime,
            continuous: values.continuous,
            contractor: values.contractor,
            effectiveWorkMonths: effectiveMonths,
            token: tempToken
        })
    }

    const showError = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Experience is locked. You are not allowed to edit this experience.',
            life: 3000
        })
    }


    if (isLoading) return <ProgressSpinner/>

    return (
        <>
            <Toast ref={toast}/>
            <EditExperienceCard initValues={
                {
                    experienceId: experience?._id,
                    candidateId: experience?.candidateId,
                    candidateName: data?.name,
                    companyName: experience?.companyName,
                    jobTitle: experience?.jobTitle,
                    jobStartDate: dateParse(experience?.jobStartDate),
                    jobEndDate: dateParse(experience?.jobEndDate),
                    jobOngoing: experience?.jobOngoing,
                    jobFullTime: experience?.jobFullTime,
                    continuous: experience?.continuous,
                    contractor: experience?.contractor,
                    effectiveWorkMonths: experience?.effectiveWorkMonths
                }
            } onUpdate={updateExperience}/>
        </>
    )
}
export default CandidateExperienceUpdate