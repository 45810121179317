import {Menubar} from "primereact/menubar"
import React, {SyntheticEvent} from "react"
import logo from "../resources/LogoSymbolOnly.png"
import {Outlet, useNavigate} from "react-router-dom"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import MainAppMenuEnd from "../components/MainApp/MainAppMenuEnd"
import useRoleStatus from "../hooks/useRoleStatus"
import {UserRoles} from "../models/Names"
import {ProgressSpinner} from "primereact/progressspinner"
import usePageAnalytics from "../hooks/usePageAnalytics"


const MainPageApp = () => {

    const navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.AGENCY, UserRoles.ADMIN])


    const agencyMenuItems = [
        {
            icon: 'pi pi-plus',
            command: () => navigate(RouteFrontEnd.NEW_CANDIDATE)
        }
    ]
    const adminMenuItems = [
        {
            label: 'Inspections',
            command: () => navigate(RouteFrontEnd.INSPECTIONS)

        },
        {
            label: 'Admins',
            command: () => navigate(RouteFrontEnd.ADMIN_USERS)
        },
        {
            label: 'Agencies',
            command: () => navigate(RouteFrontEnd.AGENCIES)
        },
        {
            icon: 'pi pi-plus',
            command: () => navigate(RouteFrontEnd.NEW_AGENCY)
        }
    ]

    const isAdmin: boolean = roleStatus.role === UserRoles.ADMIN

    usePageAnalytics(isAdmin ? "/"+RouteFrontEnd.INSPECTIONS : "/"+RouteFrontEnd.CANDIDATES, "MainPageApp")

    const onLogoClick = (event: SyntheticEvent) => {
        event.preventDefault()
        if (isAdmin) navigate(RouteFrontEnd.INSPECTIONS)
        else navigate(RouteFrontEnd.CANDIDATES)
    }

    const start = <img
        src={logo}
        alt="logo"
        height="40"
        className="mr-2 h-4rem"
        onClick={onLogoClick}
        style={{cursor: 'pointer'}}
    ></img>

    const end = <MainAppMenuEnd isAdmin={isAdmin}/>

    if (roleStatus.isFinished && !roleStatus) {
        navigate(RouteFrontEnd.LOGIN)
    }

    if (!roleStatus.isFinished) return <ProgressSpinner/>


    return (
        <div className="App">
            <div className="card p-4">
                <Menubar model={isAdmin ? adminMenuItems : agencyMenuItems} start={start} end={end}/>
                <Outlet/>
            </div>
        </div>
    )
}

export default MainPageApp