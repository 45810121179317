import {useFormik} from "formik"
import * as Yup from "yup"
import {Dialog} from "primereact/dialog"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"

type InviteFormCardProps = {
    displayDialog: boolean
    onAdd: (email: string) => void
    onCancel: () => void
}
const InviteFormCard = (props: InviteFormCardProps) => {

    const formik = useFormik({
        initialValues: {
            userEmail: ''
        },
        onSubmit: (values) => {
            props.onAdd(values.userEmail)
            formik.resetForm()
        },
        validationSchema: Yup.object({
            userEmail: Yup.string().required('Required'),
        })
    })

    const header = <h5 className="pt-2 pl-2">Invite User</h5>

    return (
        <>
            <Dialog header={header} visible={props.displayDialog} style={{width: '50vw'}}
                    onHide={() => props.onCancel()}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    formik.handleSubmit(e)
                }}>
                    <span className="p-float-label mt-6">
                        <InputText id="email"
                                   {...formik.getFieldProps('userEmail')}
                                   className="w-full"/>
                        <label htmlFor="userEmail">User email</label>
                        <small id="userEmail-error" className="p-error block">{formik.errors && formik.errors.userEmail}</small>
                    </span>
                    <Button type="submit" className="mt-6">Invite user</Button>
                </form>
            </Dialog>
        </>
    )
}
export default InviteFormCard