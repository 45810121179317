import {ConfirmDialog} from "primereact/confirmdialog"
import {useContext, useEffect, useState} from "react"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {useNavigate} from "react-router-dom"
import {useMutation} from "react-query"
import {patchCandidateConsent} from "../../networks/patchNetwork"
import {candidateAppContext} from "../../pages/MainPageCandidate"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import {UserRoles} from "../../models/Names"

type CandidateConsentDialogProps = {
    token: string,
    isConsentGiven: boolean
}
const CandidateConsentDialog = (props: CandidateConsentDialogProps) => {
    const context = useContext(candidateAppContext)
    const navigate = useNavigate()
    const [dialogVisible, setDialogVisible] = useState(false)
    const {mutate} = useMutation({
        mutationFn: patchCandidateConsent,
        onSuccess: (): void => {
            setDialogVisible(false)
            context.setCandidateData({...context.candidateData, isConsentGiven: true})
            analyticsEvent(AnalyticsEventCategory.CANDIDATE_CONSENT, {
                role: UserRoles.CANDIDATE,
                candidateId: context.candidateData.id
            })
        },
        onError: (): void => {
            setDialogVisible(true)
            analyticsEvent(AnalyticsEventCategory.CANDIDATE_CONSENT, {
                role: UserRoles.CANDIDATE,
                candidateId: context.candidateData.id,
                error: "Error while sending consent"
            })
        }
    })

    useEffect((): void => {
        if (!props.isConsentGiven) {
            setDialogVisible(true)
        }
    }, [props.isConsentGiven])

    const sendConsent = (): void => {
        mutate({tempToken: props.token})
    }
    const rejectConsent = (): void => {
        navigate(`/${RouteFrontEnd.ERROR_PAGE}`)
    }

    return (
        <ConfirmDialog
            header="Consent" visible={dialogVisible}
            message="Are you giving consent to Signify to process your data?"
            icon="pi pi-exclamation-triangle"
            acceptClassName="p-button-danger"
            rejectClassName="p-button-secondary"
            accept={sendConsent}
            reject={rejectConsent}
            onHide={() => setDialogVisible(false)}
        />
    )
}

export default CandidateConsentDialog