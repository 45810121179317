import {useNavigate} from "react-router-dom"
import React, {useState} from "react"
import {useMutation, useQueryClient} from "react-query"
import {postLoginNetwork} from "../networks/postNetwork"
import {Card} from "primereact/card"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import * as Yup from "yup"
import {useFormik} from "formik"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import {Constants, UserRoles} from "../models/Names"
import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {DtoLogin} from "../models/DtoModels"
import jwtDecode from "jwt-decode"
import usePageAnalytics from "../hooks/usePageAnalytics"
import {analyticsLoginEvent} from "../services/analytics/analytics"
import loginPicture from "../assets/SignifyLogin.png"
import './InputPasswordStyle.css'

const Login = () => {

    const navigate = useNavigate()
    const [state, setState] = useState<DtoLogin>({} as DtoLogin)
    const queryClient = useQueryClient()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const {isLoading, mutate} = useMutation({
        mutationFn: postLoginNetwork, onSuccess: (data) => {
            localStorage.setItem(Constants.TOKEN, data.accessToken)
            localStorage.setItem(Constants.REFRESH_TOKEN, data.refreshToken)
            void queryClient.invalidateQueries({queryKey: [NetworkEndpoints.LOGIN]})
            analyticsLoginEvent()

            const decodedToken = jwtDecode(data.accessToken) as any
            if (decodedToken.role === UserRoles.ADMIN) {
                navigate(`/${RouteFrontEnd.INSPECTIONS}`)
            } else if (decodedToken.role === UserRoles.AGENCY) {
                navigate(`/${RouteFrontEnd.CANDIDATES}`)
            }
        }
    })

    usePageAnalytics("/" + RouteFrontEnd.LOGIN, "Login")

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values) => {
            mutate({...state, email: values.email.toLowerCase(), password: values.password})
            formik.resetForm()
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Required'),
            password: Yup.string().required('Required')
        })
    })

    // const header = <h5 className="pt-2 pl-2">Login</h5>
    const header = <img alt="logo" src={loginPicture} className="w-4/5 mx-auto"/>

    return (
        <>
            <div className="App">
                <div className="card p-4">
                    <Card header={header} className="align-items-center w-4 mt-4 shadow-3" style={{translate: '100%'}} >
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            formik.handleSubmit(e)
                        }}>
                            <span className="p-float-label">Login:</span>
                            <span className="p-float-label mt-6">
                        <InputText id="email"
                                   {...formik.getFieldProps('email')}
                            className='w-full'/>
                        <label htmlFor="email">Email</label>
                        <small id="email-error" className="p-error block">{formik.errors && formik.errors.email}</small>
                    </span>
                            <span className="p-float-label mt-6">
                                <div className="input-wrapper">
                                    <InputText id="password" type={showPassword ? 'text' : 'password'}
                                               {...formik.getFieldProps('password')}
                                               className='w-full'/>
                                    <Button type="button" icon={showPassword ? "pi pi-eye-slash" : "pi pi-eye"}
                                            className="transparent-button"
                                            onClick={() => setShowPassword(!showPassword)}/>
                                <label htmlFor="password">Password</label>
                                <small id="password-error"
                                       className="p-error block">{formik.errors && formik.errors.password}</small>
                                </div>
                            </span>
                            <Button type="submit" className="mt-6">Login</Button>
                        </form>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default Login