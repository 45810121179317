import {useState} from "react"
import useLogout from "../../hooks/useLogout"
import {ConfirmDialog} from "primereact/confirmdialog";
import {Button} from "primereact/button";

const LogoutButton = () => {
    const [logoutFlag, setLogoutFlag]: [boolean, ((value: boolean) => void)] = useState<boolean>(false)
    const [dialogFlag, setDialogFlag]: [boolean, ((value: boolean) => void)] = useState<boolean>(false)

    useLogout(logoutFlag)

    const logout = (): void => {
        setLogoutFlag(true)
        setDialogFlag(false)
    }

    return (
        <div>
            <ConfirmDialog
                header="Logout"
                visible={dialogFlag}
                message="Are you sure you want to logout?"
                icon="pi pi-exclamation-triangle"
                acceptClassName="p-button-danger"
                rejectClassName="p-button-secondary"
                accept={() => logout()}
                reject={() => setDialogFlag(false)}
                onHide={() => setDialogFlag(false)}
            />
            <div className="flex flex-row-reverse flex-wrap card-container pt-2">
                <Button
                    icon="pi pi-power-off"
                    className="p-button-rounded p-button-warning p-button-outlined"
                    aria-label="Logout"
                    onClick={() => setDialogFlag(true)}
                ></Button>
            </div>
        </div>
    )
}

export default LogoutButton