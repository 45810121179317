export let NeltQuestionsSerbian = [
    "Na koji način ste povezani sa kandidatom?",
    "Koliko dugo ste radili sa njim/njom i u kom periodu?",
    "Na skali od 1-10 kako biste procenili njegov/njen rad?",
    "Na koji način biste opisali kandidata?",
    "Koje su bile kandidatove najjače strane?",
    "Koje su bile kandidatove oblasti za razvoj, šta je moglo biti bolje?",
    "Kakav je kandidat bio/la kao rukovodilac?",
    "Šta biste mogli reći o kandidatu u vezi sa timskim radom?",
    "Koji je njegov/njen najveći uspeh?",
    "Da li je postojalo neko ponašanje koje je kandidat ispoljavao, a da je negativno uticalo na performans?",
    "Da imate priliku, da li biste opet radili sa njim/njom? Da ili ne (ukoliko ne, molimo za obrazloženje).",
    "Na koji način je kandidat podnosio stres u vezi sa poslom?",
    "Želite li da istaknete još nešto što nismo pitali?"]

export let NeltQuestionsEnglish = [
    "What is your relationship with the candidate?",
    "How long have you worked with the candidate, and during which period?",
    "On a scale from 1-10, how would you rate his/her work?",
    "How would you describe the candidate?",
    "What were his/her strongest points?",
    "What were his/her areas for development, and what could he/she have done better?",
    "What was he/she like as a leader?",
    "What could you say about him/her in relation to teamwork?",
    "What was his/her greatest achievement?",
    "Was there any behavior he/she exhibited that negatively impacted performance?",
    "If you had the opportunity, would you work with him/her again? Yes or No (if no, please explain).",
    "How did the candidate handle job-related stress?",
    "DIs there anything else you would like to add that we haven't asked?"
    ]

export function questionByLanguage(questionNo: number, language: string) {
    if (language === "sr") {
        return NeltQuestionsSerbian[questionNo]
    } else {
        return NeltQuestionsEnglish[questionNo]
    }
}

export function neltSendButtonByLanguage(language: string) {
    if (language === "sr") {
        return "Pošalji"
    } else {
        return "Send"
    }
}

export function neltTitle(name: string | undefined, company: string | undefined, language: string): string{
    let funName: string = typeof name === "string" ? name : ""
    let funCompany: string = typeof company === "string" ? company : ""
    if(language === "sr"){
        return "Molim vas da odgovorite na sledeća pitanja u vezi kandidata " +funName+ " dok je radio u "+ funCompany+"."
    } else {
        return "Please respond to the following questions regarding the candidate "+ funName+ " while he/she was working at "+ funCompany+"."
    }
}