import {Card} from "primereact/card"
import CopyButton from "../candidate/CopyButton"
import {useNavigate, useParams} from "react-router-dom"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getAgency} from "../../../networks/getNetwork"
import {ProgressSpinner} from "primereact/progressspinner"
import React, {useEffect, useState} from "react"
import {countryNameForCode} from "../../../utils/CountryFunctions"
import AgencyEditButton from "./AgencyEditButton"
import AgencyDelete from "./AgencyDelete"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import DepositBalance from "./DepositBalance"
import OpenAgencyUsersButton from "./OpenAgencyUsersButton"
import LoginAsAgencyButton from "./LoginAsAgencyButton"

const AgencyDetails = () => {
    let {id} = useParams()
    let navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const {
        isLoading,
        isError,
        data
    } = useQuery([NetworkEndpoints.AGENCIES, id], getAgency, {staleTime: 60000})

    const [country, setCountry] = useState<string>('')

    useEffect(() => {
        if (!!data) {
            setCountry(countryNameForCode(data.country) ?? '')
        }
    }, [data?.country])


    const header = <div className="font-bold text-l ">
        <div
            onClick={() => navigate(-1)}
            style={{cursor: "pointer"}}
        >
            <i className="pi pi-angle-left text-xl mr-2"></i>
            Agencies
        </div>
        <div className="ml-5">
            <br/>
            <span className="mr-3"><AgencyEditButton id={id}/></span>
            <AgencyDelete id={id}/>
        </div>
    </div>

    const classNameForButton = "flex align-items-start justify-content-start font-bold text-white border-0 m-2"

    if (roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>
    if (isLoading) return <ProgressSpinner/>
    if (isError) return <div>error</div>

    return (
        <Card header={header} className="p-card pt-3 pl-3 m-4 shadow-3">
            <div className="flex">
                <div className="col">
                    <div className="text-xl font-bold">{data.name}</div>
                    <div className="pt-4 flex">
                        <div className="font-bold mr-2">Email:</div>
                        <i>{data.email}</i>
                        <CopyButton text={data.email}/>
                    </div>
                    <div className="pt-4 flex">
                        <div className="font-bold mr-2">Country:</div>
                        {country}</div>
                    <div className="text-m font-bold mt-2">Tax ID: {data.taxId}</div>
                    <div className="text-m font-bold mt-2">Credits: {data.credits}</div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="flex flex-column card-container">
                            <DepositBalance agencyId={data._id} buttonClassName={classNameForButton}/>
                            <OpenAgencyUsersButton agencyId={data._id} buttonClassName={classNameForButton}/>
                            <LoginAsAgencyButton agencyId={data._id} buttonClassName={classNameForButton}/>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}
export default AgencyDetails