import {ExperienceCertificate} from "../../models/AllModels"
import ExperienceCertificateSegment from "./ExperienceCertificateSegment"
import React from "react"
import {isExperienceVerified} from "../../utils/isExperienceVerified"

export type ExperienceCertificateSegmentProps = {
    experiences: ExperienceCertificate[]
}
const ExperienceListCertificateSegment = (props: ExperienceCertificateSegmentProps) => {

    return (
        <div>
            <>
                {props.experiences &&
                    props.experiences.sort((expA, expB) => {
                        if (!expA.jobEndDate && !expB.jobEndDate) {
                            return 0; // both endDates are undefined, consider them equal
                        } else if (!expA.jobEndDate) {
                            return 1; // expA is later (undefined is considered as ongoing)
                        } else if (!expB.jobEndDate) {
                            return -1; // expB is later
                        } else {
                            return Number(expB.jobEndDate) - Number(expA.jobEndDate); // both endDates are defined, compare them
                        }
                    })
                        .map((experience, index) => {
                        return (

                            <div key={index}>
                                {isExperienceVerified(experience) &&
                                    <ExperienceCertificateSegment experience={experience}/>
                                }
                            </div>
                        )
                    })}
            </>
        </div>
    )
}

export default ExperienceListCertificateSegment