export type AgencyModel = {
    _id: string,
    name: string,
    country: string,
    taxId: string,
    deleted?: boolean,
    lastSent?: Date,
    credits: number
}

export type AdminUser = {
    userId: string,
    lastLogin: Date,
    email: string,
    name: string,
    role: string,
    agencyId: string
}

export type InspectionResult = {
    _id: string,
    agencyId: string,
    candidateId: string,
    experienceId: string,
    refereeEmail: string,
    refereeName: string,
    attestationStatus: string,
    signatureStatus: string,
    checkStatus: string,
    inspectionStatus: string,
    createdAt: Date,
    updatedAt: Date,
    deleted: boolean,
    locked: boolean,
    timeSent: Date,
    refereeComment: string,
    refereeJobTitle: string
}

export type User = {
    _id: number,
    agencyId: number,
    shareId?: string,
    email: string,
    name: string,
    comment?: string
    country: string,
    image: string,
    lastSent: Date,
    status?: string,
    position?: string,
    contactForJobOffers?: boolean
    experiences: Experience[]
}

export type Verification = {
    _id: number,
    candidateId: number,
    experienceId: number,
    refereeEmail: string,
    refereeName: string,
    attestationStatus: AttestationStatus,
    signatureStatus: SignatureStatus,
    checkStatus: CheckStatus,
    inspectionStatus: SignifyInspectionStatus,
    refereeJobTitle?: string,
    deleted: boolean,
    locked?: boolean,
    refereeComment?: string
    blockExplorerLink?: string,
    transactionHash?: string,
}

// export enum ExperienceStatus { 'Draft'= 1, 'Sent', 'Confirm', 'Deny', 'Resend', 'Signed', 'Timeout' }

export type Statuses = {
    attestationStatus: AttestationStatus,
    checkStatus: CheckStatus,
    signifyInspectionStatus: SignifyInspectionStatus,
    signatureStatus: SignatureStatus
}
export enum AttestationStatus {
    Draft = "Draft",
    Sent = "Sent",
    TimedOut = "TimedOut",
    Rejected = "Rejected",
    Accepted = "Accepted",
}

export enum CheckStatus {
    Draft = "Draft",
    Suspicious = "Suspicious",
    Consistent = "Consistent",
}

export enum SignatureStatus {
    Draft = "Draft",
    Submitted = "Submitted",
    Signed = "Signed",
}

export enum SignifyInspectionStatus {
    Draft = "Draft",
    Awaiting = "Awaiting",
    Rejected = "Rejected",
    Accepted = "Accepted",
}

export type Experience = {
    _id: number,
    agencyId?: number,
    candidateId?: number,
    jobTitle: string,
    jobStartDate?: string,
    jobEndDate?: string,
    companyName?: string
    jobOngoing: boolean;
    jobFullTime: boolean;
    continuous: boolean;
    contractor: boolean;
    effectiveWorkMonths?: number,
    sentData?: Date,
    locked: boolean,
    // attestationStatus: AttestationStatus,
    // checkStatus: CheckStatus,
    // signifyInspectionStatus: SignifyInspectionStatus,
    // signatureStatus: SignatureStatus
    verifications?: string[]
}

export type ExperienceCertificate = {
    _id: number,
    jobTitle: string,
    jobStartDate?: string,
    jobEndDate?: string,
    companyName?: string
    jobOngoing: boolean;
    jobFullTime: boolean;
    continuous: boolean;
    contractor: boolean;
    effectiveWorkMonths?: number,
    sentData?: Date,
    locked: boolean,
    verifications?: Verification[]
}

export type CountryCode = {
    Name: string,
    Code: string
}

export interface ResponseSingleObject<T> {
    type: 'success' | 'error'
    message: string
    object?: T
}

export interface ResponseWithoutObject {
    type: 'success' | 'error'
    message: string
}