import React, {useEffect} from "react"
import {ConfirmDialog} from "primereact/confirmdialog"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getQueryByIdNetwork, getQueryByParamsNetwork} from "../../../networks/getNetwork"
import {Experience, User} from "../../../models/AllModels"
import {Avatar} from "primereact/avatar"
import {
    avatarImageGenerator,
    dateShow,
    getWorkMonthsBetween,
    getYearsAndMonthsString
} from "../../../utils/UtilFunctions"
import ExperienceWorkStatusView from "../experience/ExperienceWorkStatusView"

type InspectionAdditionalInformationDialogProps = {
    experienceId: string,
    candidateId: string,
    visible: boolean,
    onHide: () => void
}
const InspectionAdditionalInformationDialog = (props: InspectionAdditionalInformationDialogProps) => {
    const experienceQuery = useQuery([NetworkEndpoints.EXPERIENCE_BY_ID, undefined, `ids=["${props.experienceId}"]`], getQueryByParamsNetwork, {staleTime: 60000, enabled: !!props.experienceId})
    const candidateQuery = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, props.candidateId], getQueryByIdNetwork, {staleTime: 60000, enabled: !!props.candidateId})
    const [effectiveMonthsString, setEffectiveMonthsString] = React.useState<string>('')
    const [jobStartDate, setJobStartDate] = React.useState('')
    const [jobEndDate, setJobEndDate] = React.useState('')
    const [candidate, setCandidate] = React.useState<User|undefined>(undefined)
    const experience = experienceQuery.data?.map((e: Experience) => e).pop()

    useEffect(() => {
        if(!!experience) {
            const effectiveMonths: number = getWorkMonthsBetween(experience.jobStartDate!, experience.jobEndDate!)
            setEffectiveMonthsString(getYearsAndMonthsString(effectiveMonths))
            setJobStartDate(dateShow(Number(experience.jobStartDate)))
            setJobEndDate(dateShow(Number(experience.jobEndDate)))
        }

        if(!!candidateQuery.data) {
            setCandidate(candidateQuery.data)
        }
    }, [props.experienceId, props.candidateId, experience])

    return (
        <>
            <ConfirmDialog
            header="Information"
            visible={props.visible}
            message={
                <div className='flex'>
                    <div className='col'>
                        <Avatar image={avatarImageGenerator(props.candidateId)} className="p-mr-2" size="large"
                                shape="square"/>
                        <div className='pt-3 flex'>
                            <div className='font-bold pr-2'>Candidate Name:</div>
                            {candidate?.name}</div>
                        <div className='pt-3 flex'>
                            <div className='font-bold pr-2'>Company:</div>
                            {experience?.companyName}</div>
                        <div className='pt-3 flex'>
                            <div className='font-bold pr-2'>Position:</div>
                            {experience?.jobTitle}</div>
                        <br></br>
                        <div className='pt-3 flex'>
                            <div className='font-bold pr-2'>Duration:</div>
                            {effectiveMonthsString} ({jobStartDate} → {jobEndDate})
                        </div>
                        {!!experience && <ExperienceWorkStatusView
                            jobOngoing={experience.jobOngoing}
                            effectiveWorkMonths={getWorkMonthsBetween(experience.jobStartDate!, experience.jobEndDate!)}
                            jobFullTime={experience.jobFullTime}
                            contractor={experience.contractor}
                            continuous={experience.continuous} />}
                    </div>
                </div>
            }
            acceptClassName="p-button"
            accept={() => props.onHide()}
            reject={() => props.onHide()}
            onHide={() => props.onHide}
        />

        </>
    )
}

export default InspectionAdditionalInformationDialog