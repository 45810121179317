import {useNavigate} from "react-router-dom"
import {useRef, useState} from "react"
import {useMutation, useQueryClient} from "react-query"
import {Toast} from "primereact/toast"
import {DtoAgency} from "../../../models/DtoModels"
import {postAgencyNetwork} from "../../../networks/postNetwork"
import {refreshOnAgencyListChange} from "../../../utils/RefreshFunctions"
import AgencyFormCard from "./AgencyFormCard"
import {AgencyModel} from "../../../models/AllModels"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"


const NewAgency = () => {
    const navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const [state, setState] = useState<DtoAgency>({} as DtoAgency)
    const queryClient = useQueryClient()
    const {isError, mutate} = useMutation({
        mutationFn: postAgencyNetwork,
        onSuccess: async (data) => {
            void refreshOnAgencyListChange(queryClient)
            analyticsEvent(AnalyticsEventCategory.CREATE_AGENCY, {
                role: UserRoles.ADMIN,
                agencyId: data.id, //TODO check if this is correct
            })
            navigate(-1)
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.CREATE_AGENCY, {
                role: UserRoles.ADMIN,
                error: "Error in creating agency"
            })
        }
    })

    const toast = useRef(null)

    usePageAnalytics("/"+RouteFrontEnd.NEW_AGENCY, "New Agency")

    const showSuccess = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully added agency',
            life: 3000
        })
    }

    const showError = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Error in sending invitation to agency contact support',
            life: 3000
        })
    }

    const newAgencySend = (values: { name: string,country: string, taxId: string}) => {
        mutate({...state, name: values.name, country: values.country, taxId: values.taxId})
    }

    if(roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>

    if (isError) {
        return <div>Error in adding agency</div>
    }

    return (
        <div>
            <Toast ref={toast}/>
            <AgencyFormCard initialValues={{} as AgencyModel} submit={newAgencySend}/>
        </div>
    )
}

export default NewAgency