import {Card} from "primereact/card"
import {InputTextarea} from "primereact/inputtextarea"
import React, {useState} from "react"
import {Verification} from "../../models/AllModels"
import {Checkbox} from "primereact/checkbox"

type Props = {
    verification: Verification | undefined,
    title: string,
    onTitleChange: (text: string) => void,
    onSetConsent: (isConsentGiven: boolean) => void
}
const ManagerTitleAndConsent = ({verification, title, onTitleChange, onSetConsent}: Props) => {


    const [value, setValue] = useState<string>('')
    const [consent, setConsent] = useState<boolean>(false)

    // const submit = (event: SyntheticEvent) => {
    //     event.preventDefault()
    //     onTitleChange(value)
    // }
    //
    // const footer = <div className="flex-auto align-items-center justify-content-center">
    //     <Button onClick={submit}
    //             className="flex-auto align-items-center justify-content-center bg-blue-600 font-bold text-white-alpha-80 m-2 px-5 py-3 border-round border-0"
    //             disabled={value.length < 3 || !consent}>Submit</Button>
    // </div>
    const header = <div className="col-7 font-bold md:text-xs lg:text-xl text-gray-800">
        Please specify your title while you were working with the candidate
    </div>

    return (
        <>
            <Card header={header} className="p-card p-4 m-4 shadow-3">
                <InputTextarea className="col-12 focus" maxLength={55} value={value} onChange={
                    (e) => {
                        e.preventDefault()
                        onTitleChange(e.target.value)
                        setValue(e.target.value)
                    }}/>
                <Checkbox className='mt-1' inputId="consent16" checked={consent}
                          onChange={(e) => {
                              setConsent(e.checked)
                                onSetConsent(consent)
                          }}/>
                <label htmlFor="consent16" className="p-checkbox-label ml-2 text-sm">I consent to the processing of my
                    personal data, and I'm over the age of 16.</label>
            </Card>
        </>
    )
}

export default ManagerTitleAndConsent