import {useMutation, useQuery} from "react-query"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getAdminUsers} from "../../../networks/getNetwork"
import React, {useRef, useState} from "react"
import {UserRoles} from "../../../models/Names"
import {ProgressSpinner} from "primereact/progressspinner"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {AdminUser} from "../../../models/AllModels"
import {Button} from "primereact/button"
import {Toast} from "primereact/toast"
import InviteFormCard from "./InviteFormCard"
import {dateShow} from "../../../utils/UtilFunctions"
import {patchAdminInvite, patchResetPasswordInvite} from "../../../networks/patchNetwork"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"

const AdminUsersPage = () => {
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const toast = useRef(null)
    const [displayDialog, setDisplayDialog] = useState(false)
    const {isLoading, isError, data} = useQuery([NetworkEndpoints.USERS], getAdminUsers, {staleTime: 60000})
    const inviteMutation = useMutation({mutationFn: patchAdminInvite,
        onSuccess: () => {
            showSuccess('User is successfully invited')
            analyticsEvent(AnalyticsEventCategory.INVITE_ADMIN_USER, {
                role: roleStatus.role,
            })
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.INVITE_ADMIN_USER, {
                role: roleStatus.role,
                error: "Invitation failed"
            })
        }
    })
    const passwordResetMutation = useMutation({mutationFn: patchResetPasswordInvite,
        onSuccess: () => {
            analyticsEvent(AnalyticsEventCategory.RESET_PASSWORD_SENT, {
                role: roleStatus.role
            })
            showSuccess('Password reset email is sent')
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.RESET_PASSWORD_SENT, {
                role: roleStatus.role,
                error: "Password reset failed"
            })
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.ADMIN_USERS, 'Admin users page')
    const inviteAdminUser = async (email: string) => {
        inviteMutation.mutate({email: email})
        setDisplayDialog(false)
    }

    const onHide = () => {
        setDisplayDialog(false)
    }
    const onDialogShow = () => {
        setDisplayDialog(true)
    }

    const showData = (rowData: { lastLogin: number | undefined }) => {
        if(!!rowData.lastLogin){
            return dateShow(rowData.lastLogin)
        }
    }

    const passwordResetAction = (rowData: AdminUser) => {
        return <Button type="button" label="Reset" className="p-button-danger"
                       onClick={() => passwordResetMutation.mutate({email: rowData.email})}/>
    }

    const showSuccess = (message: string) => {
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000
        })
    }

    if (roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>
    if (isLoading) return <ProgressSpinner/>
    if (isError) return <div>Error</div>
    return (
        <>
            <Toast ref={toast}/>
            <InviteFormCard displayDialog={displayDialog} onAdd={inviteAdminUser} onCancel={onHide}/>
            <div className="flex flex-row align-items-center">
                <h1 className="flex ">Admins</h1>
                <Button className="flex bg-green-400 h-3rem ml-3 border-0" label="" icon="pi pi-plus" onClick={onDialogShow}/>
            </div>
            <div className="grid">
                <div className="col">
                    <DataTable
                        // selectionMode="single"
                        dataKey="userId"
                        value={data.filter((u: AdminUser) => u.role === UserRoles.ADMIN)}
                        sortMode="multiple"
                        // onRowClick={onRowClick}
                    >
                        <Column sortable header="Name" field="name"></Column>
                        <Column header="Email" field="email"/>
                        <Column header="Last login" body={showData}/>
                        <Column sortable header="Role" field="role"/>
                        <Column header="Password reste" body={passwordResetAction}/>
                    </DataTable>
                </div>
            </div>
        </>
    )
}
export default AdminUsersPage