import {SignatureStatus} from "../../models/AllModels"

type VerificationCertifiedItemProps = {
    refereeName: string,
    refereeEmail: string,
    refereeComment: string,
    refereeJobTitle: string,
    signatureStatus: SignatureStatus
}
const VerificationCertifiedItem = (props: VerificationCertifiedItemProps) => {

    if (!props.refereeComment) return null
    if(props.signatureStatus === SignatureStatus.Draft) return null

    return (
        <div className="grid align-items-center">
            <div className="col-8 ml-2">
                <div className="pb-2 pt-4"><b className="text-black-alpha-400 font-bold">Referee:</b> {props.refereeName} </div>
                {/*<div>Email: <b className="text-black-alpha-40">{props.refereeEmail}</b></div>*/}
                {!!props.refereeJobTitle &&
                    <div className="mt-2"><b className="text-black-alpha-400 font-bold">Title: </b>
                        {props.refereeJobTitle}
                    </div>
                }
                {!!props.refereeComment &&
                    <div className="mt-2"><b className="text-black-alpha-400 font-bold">Comment: </b>{props.refereeComment}
                    </div>
                }
            </div>
        </div>
    )
}
export default VerificationCertifiedItem