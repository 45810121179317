import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {getQueryNetwork} from "../../../networks/getNetwork"
import {putCandidateNetwork} from "../../../networks/putNetwork"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {DtoUpdateUser} from "../../../models/DtoModels"
import UpdateCandidateFormCard from "../../common/UpdateCandidateFormCard"
import {refreshOnCandidateChange} from "../../../utils/RefreshFunctions"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"


const UpdateCandidateForm = () => {
    let {id} = useParams()
    const navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.AGENCY, UserRoles.ADMIN])
    const candidate = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, id], getQueryNetwork, {staleTime: 60000})
    const queryClient = useQueryClient()
    const updateCandidateMutation = useMutation({
        mutationFn: putCandidateNetwork,
        onSuccess: () => {
            refreshOnCandidateChange(queryClient, undefined,id) //Maybe it should use .then for navigate
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId: id
            })
            navigate(-1)
        },
        onError: (error) => {
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId: id,
                error: "Error on updating candidate"
            })
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.UPDATE_CANDIDATE+'/'+id, 'Update Candidate Form')

    const updateCandidate = async (values: DtoUpdateUser) => {
        let lowerCaseEmail: string | undefined = ""
        if (values.email !== undefined && values.email !== null) {
            lowerCaseEmail = (values.email as string).toLowerCase();
        }
        updateCandidateMutation.mutate({
            candidateId: values.candidateId,
            name: values.name,
            email: lowerCaseEmail,
            country: values.country,
            position: values.position
        })
    }

    const header = <div className='font-bold text-l flex relative pt-2'>
        <div onClick={() => navigate(-1)}>
            <i className="pi pi-angle-left text-l mr-2"></i>
            <span className='pt-2 pl-2 text-l font-bold'
                  style={{cursor: 'pointer'}}
            >{candidate?.data?.name}</span></div>
    </div>

    return (
        <>
            <UpdateCandidateFormCard header={header} initialValues={{
                candidateId: candidate.data._id,
                name: candidate.data.name,
                email: candidate.data.email,
                country: candidate.data.country,
                position: candidate.data.position
            }} onUpdate={updateCandidate}/>
        </>
    )
}

export default UpdateCandidateForm
