import {Card} from "primereact/card"
import {Avatar} from "primereact/avatar"
import {avatarImageGenerator} from "../../utils/UtilFunctions"
import ExperienceCustomList from "../MainApp/experience/ExperienceCustomList"
import {User} from "../../models/AllModels"
import {Button} from "primereact/button"
import {useContext, useEffect, useState} from "react"
import {countryNameForCode} from "../../utils/CountryFunctions"
import {candidateAppContext} from "../../pages/MainPageCandidate"
import InviteCandidateButton from "../MainApp/candidate/InviteCandidateButton"
import {ProgressSpinner} from "primereact/progressspinner"
import CopyButton from "../MainApp/candidate/CopyButton"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {Checkbox} from "primereact/checkbox"
import {useMutation, useQueryClient} from "react-query"
import {putCandidateNetwork} from "../../networks/putNetwork"
import {refreshOnCandidateChange} from "../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import useRoleStatus from "../../hooks/useRoleStatus"
import {UserRoles} from "../../models/Names"
import {Tooltip} from "primereact/tooltip"

type CandidateCardProps = {
    header: JSX.Element,
    data: User,
    onClick: () => string,
    addExperience: () => void
}
const CandidateCard = (props: CandidateCardProps) => {
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const queryClient = useQueryClient()
    const roleStatus = useRoleStatus([UserRoles.AGENCY, UserRoles.ADMIN, UserRoles.CANDIDATE])

    const [country, setCountry] = useState<string>('')
    const [checked, setChecked] = useState<boolean>(true)

    const updateCandidateMutation = useMutation({
        mutationFn: putCandidateNetwork,
        onSuccess: () => {
            refreshOnCandidateChange(queryClient, undefined, candidateData?.id) //Maybe it should use .then for navigate
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId:  candidateData?.id
            })
        },
        onError: (error) => {
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: roleStatus.role,
                candidateId:  candidateData?.id,
                error: "Error on updating candidate job availability"
            })
        }
    })

    useEffect(() => {
        if (!!props.data) {
            setCountry(countryNameForCode(props.data.country) ?? '')
            props.data.experiences.sort((a, b) => {
                if(a.jobOngoing && !b.jobOngoing) return -1
                if(!a.jobOngoing && b.jobOngoing) return 1
                return Number(b.jobStartDate) - Number(a.jobStartDate)
            })
            setChecked(props.data.contactForJobOffers ?? true)
        }
    }, [props.data])

    const changeJobOffer = (e: { value: any, checked: boolean }) => {
        setChecked(e.checked)
        updateCandidateMutation.mutate({
            candidateId:  ""+props.data._id,
            contactForJobOffers: e.checked
        })
    }

    const footer: JSX.Element = <span className="flex flex-row-reverse flex-wrap pt-2"><Button onClick={props.addExperience}>Add Experience</Button></span>

    if (!props.data) return <ProgressSpinner/>

    return (
        <Card header={props.header} className="p-card pt-3 lg:pl-3 md:pl-1 lg:m-4 md:m-1 shadow-3">
            <div className="flex flex-row flex-wrap card-container">
                <div className="col">
                    <Avatar image={avatarImageGenerator(props.data._id.toString())} className="p-mr-2" size="large"
                            shape="square"/>
                    <div className="text-xl font-bold mt-2">{props.data.name}</div>
                    <div className="pt-4 flex">
                        <div className="font-bold mr-2">Email:</div>
                        <i>{props.data.email}</i>
                        <CopyButton text={props.data.email}/>
                    </div>
                    <Tooltip target=".jobOffered" position='mouse'>
                        Select this option if you are open to job opportunities. By enabling this, you allow potential employers to view your profile and verified work experiences. This can increase your visibility to companies looking for candidates.
                    </Tooltip>
                    <div className="jobOffered field-checkbox pt-4 flex">
                        <Checkbox inputId="binary" checked={checked} onChange={changeJobOffer}/>
                        <label htmlFor="binary">Available for job offers</label>
                    </div>
                    {!!props.data.shareId &&
                        <div className="flex">
                            <a href={"../" + RouteFrontEnd.CERTIFICATE + "/" + props.data.shareId} target="_blank"
                               rel="noreferrer">
                            View Certificate </a>
                        </div>
                    }
                    {!tempToken && <div className="pt-4 flex">
                        <div className="font-bold mr-2">Position:</div> {props.data.position}
                    </div>}
                    <div className="pt-4 flex">
                        <div className="font-bold mr-2">Country:</div>
                        {country}</div>
                    {!tempToken && <InviteCandidateButton candidateId={'' + props?.data?._id} email={props?.data?.email}
                                                          lastSent={props.data.lastSent}/>}
                </div>
                <div className="col">
                    <ExperienceCustomList exp={props.data.experiences}/>
                    {footer}
                </div>
            </div>
        </Card>
    )
}

export default CandidateCard
