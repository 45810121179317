import {Experience} from "../../../models/AllModels"
import {Link} from "react-router-dom"
import ExperienceCustomDetail from "./ExperienceCustomDetail"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {useContext} from "react"
import {candidateAppContext} from "../../../pages/MainPageCandidate"

type ExperienceItemProps = {
    exp: Experience | undefined,
}
const ExperienceItem = (props: ExperienceItemProps) => {
    const {candidateData} = useContext(candidateAppContext)

    const link = () => {
        if (!!candidateData.id) {
            return `/${RouteFrontEnd.CANDIDATE}/${candidateData.id}/${RouteFrontEnd.EXPERIENCES}/${props.exp?._id.toString()}`
        } else {
            return `/${RouteFrontEnd.EXPERIENCES}/${props.exp?._id.toString()}`
        }
    }

    return (
        <>
            <div>
                {props.exp && <div key={props.exp._id}>
                    <Link to={link()} >
                    <ExperienceCustomDetail exp={props.exp} />
                    </Link>
                    </div>}
            </div>
        </>
    )
}

export default ExperienceItem