import {DataScroller} from "primereact/datascroller"
import VerificationCertifiedItem from "./VerificationCertifiedItem"
import {SignatureStatus, Verification} from "../../models/AllModels"
import "./DataScrollerStyle.css"

type VerificationListProps = {
    data: Verification[] | undefined
}
const VerificationsCertificateSegment= (props: VerificationListProps) => {

    const itemTemplate = (data: {
        refereeName: string,
        refereeEmail: string,
        refereeComment: string,
        refereeJobTitle: string,
        signatureStatus: SignatureStatus
    }) => {
        return <VerificationCertifiedItem
            refereeName={data.refereeName}
            refereeEmail={data.refereeEmail}
            refereeComment={data.refereeComment}
            refereeJobTitle={data.refereeJobTitle}
            signatureStatus={data.signatureStatus}
        />
    }

    return (
        <div className='pt-4'>
            <DataScroller className='left-aligned-header' value={props.data} itemTemplate={itemTemplate} rows={100} inline scrollHeight="350px" header="Verifications" />
        </div>
    )
}

export default VerificationsCertificateSegment