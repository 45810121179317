import {useNavigate, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import React, {useState} from "react"
import {Button} from "primereact/button"
import {Card} from "primereact/card"
import logo from "../resources/LogoSymbolOnly.png"
import {patchResetPassword} from "../networks/patchNetwork"
import {useMutation} from "react-query"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import usePageAnalytics from "../hooks/usePageAnalytics"
import {jwtDecodeId} from "../utils/UtilFunctions"
import useRoleStatus from "../hooks/useRoleStatus"
import {UserRoles} from "../models/Names"
import {analyticsEvent, AnalyticsEventCategory} from "../services/analytics/analytics"

const ResetPasswordPage = () => {
    let {token} = useParams()
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('')
    const roleStatus = useRoleStatus([UserRoles.UNKNOWN], token)
    const resetPasswordMutation = useMutation({
        mutationFn: patchResetPassword, onSuccess: () => {
            analyticsEvent(AnalyticsEventCategory.RESET_PASSWORD, {
                role: roleStatus.role,
                candidateId: jwtDecodeId(token)
            })
            navigate(RouteFrontEnd.LOGIN)
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.RESET_PASSWORD, {
                role: roleStatus.role,
                candidateId: jwtDecodeId(token),
                error: "Reset Password Failed"
            })
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.RESET_PASSWORD, "Reset Password")
    const resetPassword = () => {
        if (!!token && newPassword.length > 5) {
            resetPasswordMutation.mutate({newPassword: newPassword, tempToken: token})
        }
    }

    return (
        <Card className="align-items-center w-4 m-4 shadow-3">
            <div className="flex flex-row align-items-center">
                <img src={logo} alt="logo" height="20" width="20" className="mr-3 mb-2 w-4rem h-4rem"/>
                <h1>Reset your Password</h1>
            </div>
            <InputText type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
            <Button label="Reset Password" className="ml-2" onClick={resetPassword}
                    disabled={newPassword.length < 5}/>
        </Card>
    )
}
export default ResetPasswordPage