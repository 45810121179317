import {DeleteCandidateParams} from "../components/MainApp/candidate/CandidateDelete"
import {DeleteExperienceParams} from "../components/MainApp/experience/ExperienceDelete"
import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {getToken} from "../utils/NetworkUtil"
import {DeleteVerificationParams} from "../components/MainApp/experience/verification/VerificationDelete"

const deleteNetwork = async (myUrl: string, id?: string|undefined, tempToken?: string|undefined) => {
    const baseUrl = process.env["REACT_APP_URL_BASE"]
    var token = await getToken()

    if(!!tempToken) {
        token = tempToken
    }

    let url: string

    if(!!id){
        url = `${baseUrl}/${myUrl}/${id}`
    }else {
        url = `${baseUrl}/${myUrl}`
    }

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })

    if (!response.ok) {
        throw new Error(response.statusText)
    }
    return await response.json()
}

export const deleteAgency = async (id: string): Promise<void>  => {
    const url = `${NetworkEndpoints.AGENCIES}/${id}/internal`
    return deleteNetwork(url)
}
export const deleteCandidate = async ({ id }: DeleteCandidateParams): Promise<void>  => {
    return deleteNetwork(NetworkEndpoints.CANDIDATE, id)
}
export const deleteExperience = async ({ id, tempToken }: DeleteExperienceParams): Promise<void>  => {
    return deleteNetwork(NetworkEndpoints.EXPERIENCES, id, tempToken)
}

export const deleteVerification = async ({ id, tempToken }: DeleteVerificationParams): Promise<void>  => {
    return deleteNetwork(NetworkEndpoints.VERIFICATION, id, tempToken)
}