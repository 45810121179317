import {Card} from "primereact/card"
import {Button} from "primereact/button"
import {SyntheticEvent, useState} from "react"
import {InputTextarea} from "primereact/inputtextarea"
import UserExperienceDesc from "./UserExperienceDesc"
import {Experience, User} from "../../models/AllModels"
import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"


type ManagerDenyProps = {
    user: User | undefined,
    experience: Experience | undefined,
    onSubmit: (text: string) => void,
    onBack: () => void
}
const ManagerDeny = ({user, experience, onSubmit, onBack}: ManagerDenyProps) => {

    const [value, setValue] = useState<string>('')

    usePageAnalytics("/"+RouteFrontEnd.MANAGER, "ManagerDenyPage")
    const submit = (event: SyntheticEvent) => {
        event.preventDefault()
        onSubmit(value)
    }

    const handleBack = (event: SyntheticEvent) => {
        event.preventDefault()
        onBack()
    }

    const footer = <div className='flex-auto align-items-center justify-content-center'>
        <Button onClick={submit}
                className='flex-auto align-items-center justify-content-center bg-blue-600 font-bold text-white-alpha-80 m-2 px-5 py-3 border-round border-0'
                disabled={value.length < 5}>Send feedback</Button>
    </div>

    const header = <div className='font-bold md:text-m lg:text-xl text-gray-800'>Please tell us why this is not
        accurate</div>
    const back = <div onClick={handleBack}>
        <i className="pi pi-angle-left mr-2"
           style={{
               'fontSize': '2em',
               cursor: 'pointer'
           }}/>
    </div>

    return (
        <>
            <Card header={back} className='p-card p-4 m-4 shadow-3'>
                <UserExperienceDesc user={user} experience={experience}/>
            </Card>
            <Card header={header} footer={footer} className='p-card p-4 m-4 shadow-3'>
                <InputTextarea className='col-12 focus' rows={5} cols={30} value={value}
                               onChange={(e) => setValue(e.target.value)}/>
            </Card>
        </>
    )
}

export default ManagerDeny