import {useNavigate, useParams} from "react-router-dom"
import {useRef, useState} from "react"
import {DtoAgency} from "../../../models/DtoModels"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {refreshOnAgencyChange} from "../../../utils/RefreshFunctions"
import AgencyFormCard from "./AgencyFormCard"
import {AgencyModel} from "../../../models/AllModels"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {getAgency} from "../../../networks/getNetwork"
import {putAgencyNetwork} from "../../../networks/putNetwork"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import usePageAnalytics from "../../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"

const UpdateAgency = () => {
    let {id} = useParams()
    const roleStatus = useRoleStatus([UserRoles.ADMIN])
    const navigate = useNavigate()
    const [state, setState] = useState<DtoAgency>({} as DtoAgency)
    const {
        isLoading,
        isError,
        data
    } = useQuery([NetworkEndpoints.AGENCIES, id], getAgency, {staleTime: 60000})
    const queryClient = useQueryClient()
    const updateMutation = useMutation({
        mutationFn: putAgencyNetwork,
        onSuccess: async (data) => {
            void refreshOnAgencyChange(queryClient)
            analyticsEvent(AnalyticsEventCategory.UPDATE_AGENCY, {
                role: roleStatus.role,
                agencyId: id
            })
            navigate(-1)
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.UPDATE_AGENCY, {
                role: roleStatus.role,
                agencyId: id,
                error: "Error in updating agency"
            })
        }
    })

    usePageAnalytics('/'+RouteFrontEnd.EDIT_AGENCIES+'/'+id, 'Edit Agency'+data?.name)

    const editAgencySend = (values: { name: string, country: string, taxId: string}) => {
        console.log("editAgencySend", values)
        updateMutation.mutate({...state, agencyId: id, name: values.name, country: values.country, taxId: values.taxId})
    }

if (roleStatus.role !== UserRoles.ADMIN) return <div>Not authorised</div>

    if (isError) {
        return <div>Error in saving agency</div>
    }

    return (
        <div>
            <AgencyFormCard initialValues={{
                _id: data?._id,
                name: data?.name,
                country: data?.country,
                taxId: data?.taxId,
                credits: data?.credits
            } as AgencyModel} submit={editAgencySend}/>
        </div>
    )
}
export default UpdateAgency