import UpdateCandidateFormCard from "../common/UpdateCandidateFormCard"
import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork} from "../../networks/getNetwork"
import {DtoUpdateUser} from "../../models/DtoModels"
import {putCandidateNetwork} from "../../networks/putNetwork"
import {refreshOnCandidateChange} from "../../utils/RefreshFunctions"
import {ProgressSpinner} from "primereact/progressspinner"
import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import {UserRoles} from "../../models/Names"

const CandidateUpdateCandidate = () => {
    const {id} = useParams()
    const candidate = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, id], getQueryByTempTokenNetwork, {staleTime: 60000})
    const queryClient = useQueryClient()
    const updateCandidateMutation = useMutation({
        mutationFn: putCandidateNetwork,
        onSuccess: () => {
            refreshOnCandidateChange(queryClient, id) //Maybe it should use .then for navigate
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: UserRoles.CANDIDATE,
                candidateId: id
            })
            navigate(-1)
        },
        onError: (error) => {
            analyticsEvent(AnalyticsEventCategory.UPDATE_CANDIDATE, {
                role: UserRoles.CANDIDATE,
                candidateId: id,
                error: "Error on updating candidate"
            })
        }
    })
    const navigate = useNavigate()

    usePageAnalytics("/"+RouteFrontEnd.CANDIDATE+"/"+id+"/edit",'CandidateUpdateCandidate')

    const updateCandidate = async (values: DtoUpdateUser) => {
        updateCandidateMutation.mutate({
            candidateId: values.candidateId,
            name: values.name,
            email: values.email,
            country: values.country,
            token: id
        })
    }

    const header = <div className="font-bold text-l flex relative pt-2">
        <div onClick={() => navigate(-1)}>
            <i className="pi pi-angle-left text-l mr-2" style={{cursor: 'pointer'}}></i>
            <span
                className="pt-2 pl-2 text-l font-bold"
                style={{cursor: 'pointer'}}
            >{candidate?.data?.name}</span>
        </div>
    </div>

    if(candidate.isLoading) return <ProgressSpinner/>
    return (
        <UpdateCandidateFormCard header={header} initialValues={{
            candidateId: candidate.data._id,
            name: candidate.data.name,
            email: candidate.data.email,
            country: candidate.data.country,
        }} onUpdate={updateCandidate}/>
    )
}

export default CandidateUpdateCandidate