import React, {useRef} from "react"
import {Button} from "primereact/button"
import {Toast} from "primereact/toast"

type SendVerificationButtonProps = {
    refereeComment: string | undefined,
    locked: boolean | undefined,
    sendVerification: () => void
}
const SendVerificationButton = (props: SendVerificationButtonProps) => {
    const toast = useRef(null) as any

    const submit = (): void => {
        toast.current.show({
            severity: 'info',
            summary: 'Success',
            detail: 'Email sent to verifier',
            life: 3000
        })
        props.sendVerification()
    }

    return (
        <>
            <Toast position="top-right" ref={toast}/>
            {(!props.locked && !props.refereeComment) &&
                <Button className="col-1 ml-3 bg-blue-300 p-2 border-0"
                        tooltip="Send for verification"
                        tooltipOptions={{position: 'top'}}
                        onClick={submit}>
                    <i className="pi pi-cloud-upload"
                       style={{'fontSize': '1em'}}></i>
                </Button>}
        </>
    )
}

export default SendVerificationButton