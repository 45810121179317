import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Experience, User} from "../../../models/AllModels"
import {Link, useNavigate} from "react-router-dom"
import {Avatar} from "primereact/avatar"
import {avatarImageGenerator, candidateColor} from "../../../utils/UtilFunctions"
import React, {useEffect, useState} from "react"
import {useQuery} from "react-query"
import {getCandidates} from "../../../networks/getNetwork"
import {NetworkEndpoints} from "../../../models/NetworkEndpoints"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import {countryNameForCode} from "../../../utils/CountryFunctions"
import {ProgressSpinner} from "primereact/progressspinner"
import {Button} from "primereact/button"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import {FilterMatchMode} from "primereact/api"
import {InputText} from "primereact/inputtext"
import usePageAnalytics from "../../../hooks/usePageAnalytics"

const CandidateList = () => {
    const navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.ADMIN, UserRoles.AGENCY])
    const {isLoading, data, isError} = useQuery([NetworkEndpoints.CANDIDATES], getCandidates, {staleTime: 60000})

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        country: { value: null, matchMode: FilterMatchMode.CONTAINS },
        position: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('')

    usePageAnalytics('/'+RouteFrontEnd.CANDIDATES, 'Candidates')

    useEffect((): void => {
        if (isError) {
            console.log("Redirect to login page")
            navigate('/')
        }
    }, [isError, navigate])

    const onGlobalFilterChange = (e: { target: { value: any } }) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters['global'].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }
    const clearFilter = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'country': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'position': { value: null, matchMode: FilterMatchMode.CONTAINS }

        })
        setGlobalFilterValue('')
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    const avatarBodyTemplate = (rowData: User) => {
        return <Avatar image={avatarImageGenerator(rowData._id.toString())} className="p-mr-2" shape="square"/>
    }
    const displayName = (rowData: User) => {
        return <span className={candidateColor(rowData?.status)} >{rowData.name} </span>
    }
    const displayNumberOfExp = (rowData: { experiences: Array<Experience> }) => {
        return !!rowData.experiences ? rowData.experiences.length : 0
    }

    const displayStatus = (rowData: User) => {
        return <span className={candidateColor(rowData?.status)} >{rowData.status}</span>
    }

    const displayCountry = (rowData: { country: string }) => {
        return countryNameForCode(rowData.country)
    }

    const actionBodyTemplate = (rowData: User) => {
        return <Link to={rowData._id.toString()} className="text-indigo-800"><i
            className="pi pi-angle-right text-xl"></i></Link>
    }

    const onRowClick = (event: any): void => {
        navigate(`/${RouteFrontEnd.CANDIDATES}/${event.data._id}`)
    }

    if (isLoading) return <ProgressSpinner/>

    return (
        <>
            <div className="flex flex-row align-items-center">
                <h1 className="flex ">Candidates</h1>
                {roleStatus.role === UserRoles.ADMIN &&<Button className="flex bg-green-400 h-3rem ml-3 border-0"  icon="pi pi-plus"
                       onClick={e => navigate('/'+RouteFrontEnd.NEW_CANDIDATE, {replace: true})} />}
            </div>
            <div className="grid">
                <div className="col">
                    <DataTable
                        header={renderHeader()}
                        selectionMode="single"
                        dataKey="id"
                        scrollable
                        scrollHeight="600px"
                        value={data}
                        sortMode="multiple"
                        filters={filters}
                        globalFilterFields={["name", "country", "position", "status"]}
                        onRowClick={onRowClick}
                    >
                        <Column header="" body={avatarBodyTemplate}></Column>
                        <Column sortable header="Name" field="name" body={displayName}></Column>
                        <Column sortable header="Country" field="country" body={displayCountry}></Column>
                        <Column sortable header="Position" field="position"></Column>
                        <Column sortable header="Status" field="status" body={displayStatus}></Column>
                        <Column header="Experiences" body={displayNumberOfExp}></Column>
                        <Column header="" body={actionBodyTemplate}/>
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default CandidateList