
type props = {
    jobOngoing: boolean,
    continuous: boolean,
    effectiveWorkMonths: number,
    jobFullTime: boolean,
    contractor: boolean,
}
const ExperienceWorkStatusView = ({jobOngoing, continuous, effectiveWorkMonths, jobFullTime, contractor}: props) => {
    return(
        <div className='mt-3'>
            {jobOngoing && <div className='font-bold mt-2'><span className='font-light text-gray-600'>(At the specific moment of verification, the candidate was still actively engaged in their role.)</span></div>}
            {!continuous && <div className='mt-2'><span className='font-bold'>With significant breaks.</span> <span className=''> Works for {effectiveWorkMonths} months</span> </div>}
            {!jobFullTime && <div className='font-bold mt-2'>Part time</div>}
            {contractor && <div className='font-bold mt-2'>As contractor</div>}
        </div>
    )
}

export default ExperienceWorkStatusView