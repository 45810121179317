import ReactGA from "react-ga4"
import {AnalyticsEventCategory, AnalyticsEventParams} from "../../services/analytics/analytics"

export const ga4PageView = (url: string, pageTitle: string) => {
    ReactGA.send({ hitType: "pageview", page: url, title: pageTitle });
}

export const ga4LoginEvent = () => {
    ReactGA.send({ hitType: "event", eventCategory: "login", eventAction: {method: "Custom"} });
}

export const ga4Event = (category: AnalyticsEventCategory, params: AnalyticsEventParams) => {
    ReactGA.event(category, params)
}