import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import MainPageApp from "./pages/MainPageApp";
import ManagerPage from "./pages/ManagerPage";
import CandidateList from "./components/MainApp/candidate/CandidateList"
import CandidateDetails from "./components/MainApp/candidate/CandidateDetails"
import ExperienceDetails from "./components/MainApp/experience/ExperienceDetails"
import NewCandidateForm from "./components/MainApp/candidate/NewCandidateForm"
import NewExperienceForm from "./components/MainApp/experience/NewExperienceForm"
import {QueryClient, QueryClientProvider} from "react-query"
import Login from "./pages/Login"
import UpdateCandidateForm from "./components/MainApp/candidate/UpdateCandidateForm"
import ExperienceEditForm from "./components/MainApp/experience/ExperienceEditForm"
import MainPageCandidate from "./pages/MainPageCandidate"
import CandidateContent from "./components/Candidate/CandidateContent"
import CandidateUpdateCandidate from "./components/Candidate/CandidateUpdateCandidate"
import CandidateNewExperience from "./components/Candidate/CandidateNewExperience"
import CandidateExperienceDetails from "./components/Candidate/CandidateExperienceDetails"
import CandidateExperienceUpdate from "./components/Candidate/CandidateExperienceUpdate"
import AgencyList from "./components/MainApp/agency/AgencyList"
import {RouteFrontEnd} from "./models/RouteFrontEnd"
import AgencyDetails from "./components/MainApp/agency/AgencyDetails"
import NewAgency from "./components/MainApp/agency/NewAgency"
import UpdateAgency from "./components/MainApp/agency/UpdateAgency"
import InspectionPage from "./components/MainApp/admin/InspectionPage"
import AdminUsersPage from "./components/MainApp/admin/AdminUsersPage"
import AgencyUsersPage from "./components/MainApp/agency/AgencyUsersPage"
import ResetPasswordPage from "./pages/ResetPasswordPage"
import RegistrationPage from "./pages/RegistrationPage"
import ReactGA from "react-ga4"
import CertificatePage from "./components/Certificate/CertificatePage"

function App() {
    const queryClient = new QueryClient()

    useEffect(() => {
        ReactGA.initialize('G-SR4GBCBRVD')
    }, [])

  return (
      <QueryClientProvider client={queryClient}>
        <Routes>
            <Route path={'/'+RouteFrontEnd.MANAGER+'/:id'} element={<ManagerPage />} />
            <Route path={'/'+RouteFrontEnd.RESET_PASSWORD+'/:token'} element={<ResetPasswordPage />} />
            <Route path={ '/'+RouteFrontEnd.REGISTRATION+'/:token'} element={<RegistrationPage />} />
            <Route path={'/'+RouteFrontEnd.ERROR_PAGE} element={<div>Error 404 Page not find!!!</div>} />
            <Route path={RouteFrontEnd.LOGIN} element={<Login />} />
            <Route path={RouteFrontEnd.CERTIFICATE+'/:sharedId'} element={<CertificatePage />} />
            <Route element={<MainPageApp />} >
                <Route path={"/"+RouteFrontEnd.AGENCIES} element={<AgencyList />} />
                <Route path= {"/"+RouteFrontEnd.AGENCIES+"/:id"} element={<AgencyDetails />} />
                <Route path= {"/"+RouteFrontEnd.NEW_AGENCY} element={<NewAgency />} />
                <Route path={ '/'+RouteFrontEnd.EDIT_AGENCIES+'/:id'} element={<UpdateAgency />} />
                <Route path={'/'+RouteFrontEnd.INSPECTIONS} element={<InspectionPage />} />
                <Route path={'/'+RouteFrontEnd.ADMIN_USERS} element={<AdminUsersPage />} />
                <Route path={'/'+RouteFrontEnd.AGENCY_USERS+'/:id'} element={<AgencyUsersPage />} />

                <Route path={'/'+RouteFrontEnd.CANDIDATES} element={<CandidateList />} />
                <Route path={'/'+RouteFrontEnd.CANDIDATES+'/:id'} element={<CandidateDetails />} />
                <Route path={'/'+RouteFrontEnd.CANDIDATES+'/:id/'+RouteFrontEnd.NEW_EXPERIENCE} element={<NewExperienceForm />} />
                <Route path={'/'+RouteFrontEnd.EXPERIENCES+'/:id'} element={<ExperienceDetails />} />
                <Route path={'/'+RouteFrontEnd.NEW_CANDIDATE} element={<NewCandidateForm />}/>
                <Route path={'/'+RouteFrontEnd.UPDATE_CANDIDATE+'/:id'} element={<UpdateCandidateForm />} />

                <Route path={'/'+RouteFrontEnd.EXPERIENCES+'/:id'} element={<ExperienceDetails />} />
                <Route path={'/'+RouteFrontEnd.UPDATE_EXPERIENCE+'/:id'} element={<ExperienceEditForm />} />
            </Route>
            <Route path='/candidate/:id' element={<MainPageCandidate />} >
                <Route path='/candidate/:id' element={<CandidateContent />} />
                <Route path='/candidate/:id/edit/' element={<CandidateUpdateCandidate />} />
                <Route path='/candidate/:id/experiences/new' element={<CandidateNewExperience />} />
                <Route path='/candidate/:id/experiences/:experienceId' element={<CandidateExperienceDetails />} />
                <Route path='/candidate/:id/experiences/:experienceId/edit' element={<CandidateExperienceUpdate />} />
            </Route>
        </Routes>
      </QueryClientProvider>
  )
}
// export declare interface AppProps {
//   children?: React.ReactNode;
//   childrenElement: JSX.Element;
//   handleChange?: React.FormEventHandler<HTMLInputElement>;
//   style?: React.CSSProperties;
// }
export default App
