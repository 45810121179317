import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {getToken} from "../utils/NetworkUtil"

const getNetwork = async (myURl: string, id?: string, params?: string) => {
    const baseUrl = process.env["REACT_APP_URL_BASE"]
    let response = null
    const token = await getToken()

    if (id) {
        response = await fetch(`${baseUrl}/${myURl}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    } else if (params) {
        response = await fetch(`${baseUrl}/${myURl}?${params}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    } else {
        response = await fetch(`${baseUrl}/${myURl}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    }

    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

const getAuthNetwork = async (myURl: string, id?: string) => {
    const baseUrl = process.env["REACT_APP_AUTH_BASE_URL"]
    let response = null

    const token = await getToken()

    if (id) {
        response = await fetch(`${baseUrl}/${myURl}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    } else {
        response = await fetch(`${baseUrl}/${myURl}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    }

    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

export type Params = {
    queryKey: (string | undefined)[]
}
export const getQueryNetwork = async (params: Params) => {
    const [_key] = params.queryKey
    if (!!_key) {
        return getNetwork(_key)
    }
}

export const getQueryByIdNetwork = async (params: Params) => {
    const [_key, id] = params.queryKey
    if (!!_key) {
        return getNetwork(_key, id)
    }
}

export const getQueryByParamsNetwork = async (params: Params) => {
    const [_key, id, param] = params.queryKey
    if (!!_key) {
        return getNetwork(_key, undefined, param)
    }
}

export const getCandidates = async () => {
    const url = NetworkEndpoints.CANDIDATES
    return getNetwork(url)
}

export const getAdminUsers = async (params: Params) => {
    const url = NetworkEndpoints.USERS
    return getAuthNetwork(url)
}

export const getUser = async (params: Params) =>{
    const url = NetworkEndpoints.USER
    return getAuthNetwork(url)
}
export const getAgencies = async () => {
    const url = NetworkEndpoints.ADMIN_AGENCIES
    return getNetwork(url)
}

export const getPendingInvites = async (params: Params) => {
    const [_key, id ] = params.queryKey
    const url = NetworkEndpoints.USER_PENDING_INVITE + '/'+id + "/internal"
    return getNetwork(url)
}

export const getAgency = async (params: Params) => {
    const [_key, id ] = params.queryKey
    const url = NetworkEndpoints.AGENCIES+"/"+id+"/internal"
    return getNetwork(url)
}

export const getQueryByTempTokenNetwork = async (params: Params) => {
    const [_key, tempToken, id ] = params.queryKey
    const baseUrl = process.env["REACT_APP_URL_BASE"]

    var token = await getToken()

    if(!!tempToken){
        token = tempToken
    }

    const url = !!id ? `${baseUrl}/${_key}/${id}` : `${baseUrl}/${_key}`

    const response = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

export const getQueryWithoutAuthNetwork = async (params: Params) => {
    const [_key, id ] = params.queryKey
    const baseUrl = process.env["REACT_APP_URL_BASE"]

    const url = !!id ? `${baseUrl}/${_key}/${id}` : `${baseUrl}/${_key}`
    const response = await fetch(url)
    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

export default getNetwork