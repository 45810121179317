import {Experience} from "../../../models/AllModels"
import {Badge} from "primereact/badge"
import {getWorkMonthsBetween, getYearsAndMonthsString} from "../../../utils/UtilFunctions"

type ExperienceCustomDetailProps = {
    exp: Experience
}
const ExperienceCustomDetail = (props: ExperienceCustomDetailProps) => {
    const displayHeader = <div className='font-bold m-2 text-black-alpha-80'>{props.exp.jobTitle}
        {!!props.exp.verifications && <Badge className="m-2"
        value={props.exp.verifications?.length}></Badge>}
    </div>

    const effectiveMonths: number = getWorkMonthsBetween(props.exp.jobStartDate!, props.exp.jobEndDate!)
    const effectiveMonthsString: string = getYearsAndMonthsString(effectiveMonths)
    return (
        <div className='p-card text-sm pl-2 pt-2 mb-4 shadow-2'>
            {displayHeader}
            <div className='flex pb-4'>
                <div className='ml-2 text-black-alpha-80'>
                    <b className='text-black-alpha-40'>{props.exp.companyName}</b>
                </div>
                <div className='ml-2 text-black-alpha-80'>
                    <b className='text-black-alpha-40'>{effectiveMonthsString}</b>
                </div>
            </div>
        </div>
    )
}
export default ExperienceCustomDetail