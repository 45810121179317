import {Button} from "primereact/button"
import {Toast} from "primereact/toast"
import {useEffect, useRef, useState} from "react"
import {QueryClient, useMutation, useQueryClient} from "react-query"
import {patchInviteCandidate} from "../../../networks/patchNetwork"
import {ConfirmDialog} from "primereact/confirmdialog"
import {refreshOnCandidateChange} from "../../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"

type InviteCandidateButtonProps = {
    candidateId: string,
    email: string,
    lastSent: Date
}
const InviteCandidateButton = (props: InviteCandidateButtonProps) => {
    const toast = useRef(null) as any
    const [consentText, setConsentText]: [string, ((value: string) => void)] = useState<string>('Invite')
    const [dialogFlag, setDialogFlag]: [boolean, ((value: boolean) => void)] = useState<boolean>(false)
    const userStatus = useRoleStatus([UserRoles.AGENCY, UserRoles.ADMIN])
    const queryClient: QueryClient = useQueryClient()
    const {mutate} = useMutation({
        mutationFn: patchInviteCandidate,
        onSuccess: (): void => {
            refreshOnCandidateChange(queryClient, props.candidateId) //Maybe it should use .then for showInfo
            analyticsEvent(AnalyticsEventCategory.INVITE_CANDIDATE, {
                role: userStatus.role,
                candidateId: props.candidateId
            })
            showInfo()
        },
        onError: (): void => {
            analyticsEvent(AnalyticsEventCategory.INVITE_CANDIDATE, {
                role: userStatus.role,
                candidateId: props.candidateId,
                error: "Error while sending a candidate invite"
            })
            showError()
        }
    })

    useEffect((): void => {
        if (!!props.lastSent) {
            setConsentText('Resend Invite')
        }
    }, [props.lastSent])


    const sendInvite = (): void => {
        mutate({
            id: props.candidateId,
            email: props.email.toLowerCase()
        })
    }

    const showInfo = (): void => {
        toast.current.show({
            severity: 'info',
            summary: 'Success',
            detail: 'Candidate invite sent',
            life: 3000
        })
    }

    const showError = (): void => {
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Error while sending a candidate invite, please contact support',
            life: 3000
        })
    }

    return (
        <div>
            <ConfirmDialog
                header="Invite Resend"
                visible={dialogFlag}
                message="Are you sure you want to resend the invite?"
                icon="pi pi-exclamation-triangle"
                acceptClassName="p-button-danger"
                rejectClassName="p-button-secondary"
                accept={sendInvite}
                reject={() => setDialogFlag(false)}
                onHide={() => setDialogFlag(false)}
            />
            <Toast position="top-right" ref={toast}/>
            <Button label={consentText} className="mt-4 bg-green-400 border-0" icon="pi pi-send" iconPos="left"
                    onClick={() => setDialogFlag(true)}/>
        </div>
    )
}

export default InviteCandidateButton