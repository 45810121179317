import {Button} from "primereact/button"
import {useNavigate} from "react-router-dom"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"

type OpenAgencyUsersButtonProps = {
    agencyId: string,
    buttonClassName?: string
}
const OpenAgencyUsersButton = (props: OpenAgencyUsersButtonProps) => {
    const navigation = useNavigate()

    const openAgencyUsers = () => {
        navigation('/'+RouteFrontEnd.AGENCY_USERS+'/'+props.agencyId, {replace: true} )
    }

    return (
        <>
            <Button label='Users' className={props.buttonClassName+" "} icon="pi pi-users" iconPos="left"
                    onClick={() => openAgencyUsers()} />
        </>
    )
}

export default OpenAgencyUsersButton