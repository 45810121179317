import {useRef} from 'react'
import {Button} from "primereact/button"
import {analyticsEvent, AnalyticsEventCategory} from "../../../services/analytics/analytics"
import {UserRoles} from "../../../models/Names"

interface CopyButtonProps {
    text: string;
}

const CopyButton = ({text}: CopyButtonProps) => {
    const textFieldRef = useRef<HTMLTextAreaElement>(null);

    const handleCopy = async (): Promise<void> => {
        if (textFieldRef.current) {
            textFieldRef.current.select();
            analyticsEvent(AnalyticsEventCategory.COPY_BUTTON_CLICK, {
                role: UserRoles.UNKNOWN
            })
            await navigator.clipboard.writeText(text);
        }
    };

    return (
        <>
            <Button icon="pi pi-copy" className="p-button-rounded p-button-secondary p-button-text"
                    onClick={handleCopy}/>
            <textarea ref={textFieldRef} value={text} style={{position: 'absolute', left: '-9999px'}} readOnly/>
        </>
    );
};

export default CopyButton;