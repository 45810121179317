

export const enum Constants {
    TOKEN = "jwtToken",
    REFRESH_TOKEN = "refreshToken",
    CANDIDATE_TOKEN = "candidateToken"
}

export const enum UserRoles {
    REFEREE   = "Referee",
    CANDIDATE = "Candidate",
    AGENCY    = "Agency",
    ADMIN     = "Admin",
    UNKNOWN = "unknown",
}