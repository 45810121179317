import countryList from '../components/common/countries.json'
import {CountryCode} from "../models/AllModels"

export const countryNameForCode = (code: string) => {
    return countryList.find((country: CountryCode) => country.Code === code)?.Name;
}

export const countryForCode = (code: string) => {
    return countryList.find((country:CountryCode) => country.Code === code)
}
