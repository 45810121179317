import {InputText} from "primereact/inputtext"
import {Calendar} from "primereact/calendar"
import {Checkbox} from "primereact/checkbox"
import {Button} from "primereact/button"
import {Card} from "primereact/card"
import {useFormik} from "formik"
import {dateMilitaryFormat, dateParse} from "../../utils/UtilFunctions"
import * as Yup from "yup"

export type NewExperienceFormType = {
    companyName: string,
    jobTitle: string,
    jobStartDate: number,
    jobEndDate: number,
    jobOngoing: boolean,
    jobFullTime: boolean,
    continuous: boolean,
    contractor: boolean,
    effectiveWorkMonths: number | undefined
}
type NewExperienceCardProps = {
    header: JSX.Element,
    sendExperience: (values: NewExperienceFormType) => void
}
const NewExperienceCard = (props: NewExperienceCardProps) => {

    // @ts-ignore
    const formik = useFormik({
        initialValues: {
            companyName: '',
            jobTitle: '',
            jobStartDate: '',
            jobEndDate: '',
            jobOngoing: false,
            jobFullTime: true,
            continuous: true,
            contractor: false,
            effectiveWorkMonths: 0
        },
        onSubmit: (values) => {
            const startD: number = dateMilitaryFormat(new Date(values.jobStartDate))
            let endD: number = dateMilitaryFormat(new Date())
            if (!!values.jobEndDate) {
                endD = dateMilitaryFormat(new Date(values.jobEndDate))
            }
            const effectiveMonths = values.continuous ? undefined : values.effectiveWorkMonths
            props.sendExperience({
                companyName: values.companyName,
                jobTitle: values.jobTitle,
                jobStartDate: startD,
                jobEndDate: endD,
                jobOngoing: values.jobOngoing,
                jobFullTime: values.jobFullTime,
                continuous: values.continuous,
                contractor: values.contractor,
                effectiveWorkMonths: effectiveMonths
            })
            formik.resetForm()
        },
        validationSchema: Yup.object({
            companyName: Yup.string().required('Required'),
            jobTitle: Yup.string().required('Required'),
            jobStartDate: Yup.string().required('Required'),
            jobEndDate: Yup.string(), //.test("jobEndDate-before", "Error End Date must be before Job start date", ()=>formik.values.jobStartDate >= formik.values.jobEndDate),
            effectiveWorkMonths: Yup.number().min(0)
        })
    })

    return (
        <Card header={props.header} className="p-card lg:pt-3 md:pt-1 lg:pl-3 md:pl-0 lg:m-4 md:m-0 shadow-3">
            <div className="col-12 text-2xl font-bold text-left mb-3">New Experience</div>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex col-12 mb-3">
                <span className="p-float-label col-6 mb-3">
                        <InputText id="jobTitle" type="text" size={30}
                                   {...formik.getFieldProps('jobTitle')}
                                   className="w-full"/>
                        <label htmlFor="jobTitle">Job Title</label>
                        <small id="jobTitle-error"
                               className="p-error block">{formik.values.jobTitle && formik.errors.jobTitle}</small>
                    </span>
                    <span className="p-float-label col-6">
                        <InputText id="companyName" type="text" size={30}
                                   {...formik.getFieldProps('companyName')}
                                   className="w-full"/>
                        <label htmlFor="companyName">Company Name</label>
                        <small id="companyName-error"
                               className="p-error block">{formik.values.companyName && formik.errors.companyName}</small>
                    </span>
                </div>
                <div className="flex mb-3">
                    <div className="field col-6">
                        <label htmlFor="jobStartDate" className="mr-2">Job start date</label>
                        <Calendar id="jobStartDate" {...formik.getFieldProps('jobStartDate')} maxDate={new Date()}
                                  view="month" dateFormat="MM yy"/>
                        <small id="jobStartDate-error"
                               className="p-error block">{formik.values.jobStartDate && formik.errors.jobStartDate}</small>
                    </div>
                    <div className="field col-6" hidden={formik.values.jobOngoing}>
                        <label htmlFor="jobEndDate" className="mr-2">Job end date</label>
                        <Calendar id="jobEndDate" {...formik.getFieldProps('jobEndDate')} maxDate={new Date()}
                                  minDate={dateParse(Number(formik.values.jobStartDate))}
                                  view="month" dateFormat="MM yy"/>
                        <small id="jobEndDate-error"
                               className="p-error block">{formik.values.jobEndDate && formik.errors.jobEndDate}</small>
                    </div>
                </div>
                <div className="flex grid mr-3">
                    <span className="p-float-label col-12 md:col-6 lg:col-3">
                        <Checkbox inputId="continuous"
                                  checked={formik.values.continuous} {...formik.getFieldProps('continuous')} />
                        <label htmlFor="continuous" className="ml-4">Without significant breaks</label>
                    </span>
                    <span className="p-float-label col-12 md:col-6 lg:col-3 ">
                        <Checkbox inputId="jobOngoing"
                                  checked={formik.values.jobOngoing} {...formik.getFieldProps('jobOngoing')} />
                        <label htmlFor="jobOngoing" className="ml-4">Candidate still works here</label>
                    </span>
                    <span className="p-float-label col-12 md:col-6 lg:col-3">
                        <Checkbox inputId="jobFullTime"
                                  checked={formik.values.jobFullTime} {...formik.getFieldProps('jobFullTime')} />
                        <label htmlFor="jobFullTime" className="ml-4">Full time</label>
                    </span>
                    <span className="p-float-label col-12 md:col-6 lg:col-3">
                        <Checkbox inputId="contractor"
                                  checked={formik.values.contractor} {...formik.getFieldProps('contractor')} />
                        <label htmlFor="contractor" className="ml-4">Contractor</label>
                    </span>
                </div>
                <div className="flex mt-4 mr-3">
                    {!formik.values.continuous && <span className="p-float-label col-12 md:col-6 lg:col-3">
                        <InputText id="effectiveWorkMonths" type="number" size={30}
                                   {...formik.getFieldProps('effectiveWorkMonths')}
                                   className="w-full"/>
                        <label htmlFor="effectiveWorkMonths">Effective work months</label>
                        <small id="companyName-error"
                               className="p-error block">{formik.values.effectiveWorkMonths && formik.errors.effectiveWorkMonths}</small>
                    </span>}
                </div>
                <div className="flex col-12 mt-4">
                    <i className="pi pi-info-circle text-orange-400 mr-2" />
                    <span className='text-orange-500'>A verification tab opens after entering an experience.</span>
                </div>
                <Button type="submit" className="mt-6">Submit Experience</Button>
            </form>
        </Card>
    )
}

export default NewExperienceCard