import {useNavigate, useParams} from "react-router-dom"
import {useCallback, useEffect, useState} from "react"
import ThankYou from "../components/Manager/ThankYou"
import ManagerContent from "../components/Manager/ManagerContent"
import ManagerConfirm from "../components/Manager/ManagerConfirm"
import ManagerDeny from "../components/Manager/ManagerDeny"
import {useMutation, useQuery} from "react-query"
import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork} from "../networks/getNetwork"
import jwtDecode from "jwt-decode"
import {patchSendVerificationByReferee} from "../networks/patchNetwork"
import {ProgressSpinner} from "primereact/progressspinner"
import usePageAnalytics from "../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../models/RouteFrontEnd"
import {analyticsEvent, AnalyticsEventCategory} from "../services/analytics/analytics"
import {UserRoles} from "../models/Names"
import ManagerNeltQuestions from "../components/Manager/ManagerNeltQuestions"

export type ManagerPageState = {
    page: 'loading' | 'start' | 'accepted' | 'nelt' | 'denied' | 'submitted' | 'error'
}
const ManagerPage = () => {
    let {id} = useParams()
    const navigation = useNavigate()

    const [pageState, setPageState] = useState<ManagerPageState>({
        page: 'start'
    })
    const [managerTitle, setManagerTitle] = useState<string>('')
    const [isAccepted, setIsAccepted] = useState<boolean>(false)

    const experience = useQuery([NetworkEndpoints.EXPERIENCES, id], getQueryByTempTokenNetwork,
        {staleTime: 60000, enabled: !!id})
    const user = useQuery([NetworkEndpoints.CANDIDATE, id], getQueryByTempTokenNetwork,
        {enabled: !!id, staleTime: 60000})
    const verification = useQuery([NetworkEndpoints.VERIFICATION, id], getQueryByTempTokenNetwork,
        {enabled: !!id, staleTime: 60000})
    const mutateVerification = useMutation({
        mutationFn: patchSendVerificationByReferee,
        onSuccess: () => {
            setPageState({...pageState, page: 'submitted'})
            if (isAccepted) {
                analyticsEvent(AnalyticsEventCategory.APPROVE_BY_MANAGER, {
                    role: UserRoles.REFEREE,
                    candidateId: verification.data?.candidateId,
                    experienceId: experience.data?.experienceId,
                    verificationId: verification.data?._id
                })
            } else {
                analyticsEvent(AnalyticsEventCategory.REJECT_BY_MANAGER, {
                    role: UserRoles.REFEREE,
                    candidateId: verification.data?.candidateId,
                    experienceId: experience.data?.experienceId,
                    verificationId: verification.data?._id
                })
            }
        },
        onError: () => {
            if (isAccepted) {
                analyticsEvent(AnalyticsEventCategory.APPROVE_BY_MANAGER, {
                    role: UserRoles.REFEREE,
                    candidateId: verification.data?.candidateId,
                    experienceId: experience.data?.experienceId,
                    verificationId: verification.data?._id,
                    error: "SendVerificationError"
                })
            } else {
                analyticsEvent(AnalyticsEventCategory.REJECT_BY_MANAGER, {
                    role: UserRoles.REFEREE,
                    candidateId: verification.data?.candidateId,
                    experienceId: experience.data?.experienceId,
                    verificationId: verification.data?._id,
                    error: "SendVerificationError"
                })
            }
        }
    })

    usePageAnalytics("/" + RouteFrontEnd.MANAGER + "/" + id, "ManagerPage")

    useEffect(() => {
        var token: any | undefined = undefined
        if (!!id) {
            token = jwtDecode(id)
        }
        if (!!token && token.exp !== undefined && token.exp !== 0) {
            const currentTime = Math.floor(Date.now() / 1000)
            if (token.exp < currentTime) {
                navigation('/error') //TODO: Add Expired page
            }
        } else {
            setPageState({...pageState, page: 'error'})
        }
    }, [id])

    const onConfirm = () => {
        if (experience.data?.agencyId === "649164f2500acd36545d6ba6" || experience.data?.agencyId === "6439016ba2c4e505158bad35") {
            setIsAccepted(true)
            setPageState({...pageState, page: 'nelt'})
        } else {
            setIsAccepted(true)
            setPageState({...pageState, page: 'accepted'})
        }
    }

    const onDeny = () => {
        setIsAccepted(false)
        setPageState({...pageState, page: 'denied'})
    }

    const onSubmit = (text: string) => {
        console.log("onManagerSubmit PAGE", text)
        mutateVerification.mutate({
            token: id,
            verificationId: verification.data?._id,
            accepted: isAccepted,
            refereeJobTitle: managerTitle,
            refereeComment: text
        })
    }
    const onTitleChange = (text: string) => {
        console.log("onTitleChange PAGE", text)
        setManagerTitle(text)
    }
    const onBack = () => {
        setPageState({...pageState, page: 'start'})
    }

    const renderContent = useCallback(() => {

        if (user.isLoading || experience.isLoading || verification.isLoading) {
            return <ProgressSpinner/>

        }
        if (user.isError || experience.isError || verification.isError) {
            return <div>Error</div>
        }

        switch (pageState.page) {
            case 'loading':
                return <ProgressSpinner/>
            case 'start':
                return <ManagerContent user={user.data} experience={experience.data} verification={verification.data}
                                       title={managerTitle} onConfirm={onConfirm} onDeny={onDeny}
                                       onTitleChange={onTitleChange}/>
            case 'accepted':
                return <ManagerConfirm user={user.data} experience={experience.data} onBack={onBack}
                                       onSubmit={onSubmit}/>
            case 'denied':
                return <ManagerDeny user={user.data} experience={experience.data} onSubmit={onSubmit} onBack={onBack}/>
            case 'submitted':
                return <ThankYou/>
            case 'nelt':
                return <ManagerNeltQuestions user={user.data} experience={experience.data} onBack={onBack}
                                             onSubmit={onSubmit}/>
            case 'error':
                return <div>Error</div>
        }
    }, [experience.data, experience.isError, experience.isLoading, onBack, onConfirm, onDeny, onSubmit, pageState.page, user.data, user.isError, user.isLoading, verification.data, verification.isError, verification.isLoading])

    return (
        <>
            {renderContent()}
        </>
    )
}

export default ManagerPage
