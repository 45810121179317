import {useEffect} from "react"
import {analyticsPageView} from "../services/analytics/analytics"

const usePageAnalytics = (url: string, pageTitle: string) => {

    useEffect(() => {
        analyticsPageView(url, pageTitle)
    }, [url, pageTitle])

}

export default usePageAnalytics